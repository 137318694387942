var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __esm = (fn, res) => function __init() {
  return fn && (res = (0, fn[__getOwnPropNames(fn)[0]])(fn = 0)), res;
};
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target10) => (target10 = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target10, "default", { value: mod, enumerable: true }) : target10,
  mod
));

// ../../node_modules/.pnpm/tsup@8.0.2_@microsoft+api-extractor@7.43.0_@types+node@20.12.12__postcss@8.4.38_typescript@5.4.5/node_modules/tsup/assets/esm_shims.js
var init_esm_shims = __esm({
  "../../node_modules/.pnpm/tsup@8.0.2_@microsoft+api-extractor@7.43.0_@types+node@20.12.12__postcss@8.4.38_typescript@5.4.5/node_modules/tsup/assets/esm_shims.js"() {
    "use strict";
  }
});

// ../../node_modules/.pnpm/speakingurl@14.0.1/node_modules/speakingurl/lib/speakingurl.js
var require_speakingurl = __commonJS({
  "../../node_modules/.pnpm/speakingurl@14.0.1/node_modules/speakingurl/lib/speakingurl.js"(exports, module) {
    "use strict";
    init_esm_shims();
    (function(root) {
      "use strict";
      var charMap = {
        // latin
        "\xC0": "A",
        "\xC1": "A",
        "\xC2": "A",
        "\xC3": "A",
        "\xC4": "Ae",
        "\xC5": "A",
        "\xC6": "AE",
        "\xC7": "C",
        "\xC8": "E",
        "\xC9": "E",
        "\xCA": "E",
        "\xCB": "E",
        "\xCC": "I",
        "\xCD": "I",
        "\xCE": "I",
        "\xCF": "I",
        "\xD0": "D",
        "\xD1": "N",
        "\xD2": "O",
        "\xD3": "O",
        "\xD4": "O",
        "\xD5": "O",
        "\xD6": "Oe",
        "\u0150": "O",
        "\xD8": "O",
        "\xD9": "U",
        "\xDA": "U",
        "\xDB": "U",
        "\xDC": "Ue",
        "\u0170": "U",
        "\xDD": "Y",
        "\xDE": "TH",
        "\xDF": "ss",
        "\xE0": "a",
        "\xE1": "a",
        "\xE2": "a",
        "\xE3": "a",
        "\xE4": "ae",
        "\xE5": "a",
        "\xE6": "ae",
        "\xE7": "c",
        "\xE8": "e",
        "\xE9": "e",
        "\xEA": "e",
        "\xEB": "e",
        "\xEC": "i",
        "\xED": "i",
        "\xEE": "i",
        "\xEF": "i",
        "\xF0": "d",
        "\xF1": "n",
        "\xF2": "o",
        "\xF3": "o",
        "\xF4": "o",
        "\xF5": "o",
        "\xF6": "oe",
        "\u0151": "o",
        "\xF8": "o",
        "\xF9": "u",
        "\xFA": "u",
        "\xFB": "u",
        "\xFC": "ue",
        "\u0171": "u",
        "\xFD": "y",
        "\xFE": "th",
        "\xFF": "y",
        "\u1E9E": "SS",
        // language specific
        // Arabic
        "\u0627": "a",
        "\u0623": "a",
        "\u0625": "i",
        "\u0622": "aa",
        "\u0624": "u",
        "\u0626": "e",
        "\u0621": "a",
        "\u0628": "b",
        "\u062A": "t",
        "\u062B": "th",
        "\u062C": "j",
        "\u062D": "h",
        "\u062E": "kh",
        "\u062F": "d",
        "\u0630": "th",
        "\u0631": "r",
        "\u0632": "z",
        "\u0633": "s",
        "\u0634": "sh",
        "\u0635": "s",
        "\u0636": "dh",
        "\u0637": "t",
        "\u0638": "z",
        "\u0639": "a",
        "\u063A": "gh",
        "\u0641": "f",
        "\u0642": "q",
        "\u0643": "k",
        "\u0644": "l",
        "\u0645": "m",
        "\u0646": "n",
        "\u0647": "h",
        "\u0648": "w",
        "\u064A": "y",
        "\u0649": "a",
        "\u0629": "h",
        "\uFEFB": "la",
        "\uFEF7": "laa",
        "\uFEF9": "lai",
        "\uFEF5": "laa",
        // Persian additional characters than Arabic
        "\u06AF": "g",
        "\u0686": "ch",
        "\u067E": "p",
        "\u0698": "zh",
        "\u06A9": "k",
        "\u06CC": "y",
        // Arabic diactrics
        "\u064E": "a",
        "\u064B": "an",
        "\u0650": "e",
        "\u064D": "en",
        "\u064F": "u",
        "\u064C": "on",
        "\u0652": "",
        // Arabic numbers
        "\u0660": "0",
        "\u0661": "1",
        "\u0662": "2",
        "\u0663": "3",
        "\u0664": "4",
        "\u0665": "5",
        "\u0666": "6",
        "\u0667": "7",
        "\u0668": "8",
        "\u0669": "9",
        // Persian numbers
        "\u06F0": "0",
        "\u06F1": "1",
        "\u06F2": "2",
        "\u06F3": "3",
        "\u06F4": "4",
        "\u06F5": "5",
        "\u06F6": "6",
        "\u06F7": "7",
        "\u06F8": "8",
        "\u06F9": "9",
        // Burmese consonants
        "\u1000": "k",
        "\u1001": "kh",
        "\u1002": "g",
        "\u1003": "ga",
        "\u1004": "ng",
        "\u1005": "s",
        "\u1006": "sa",
        "\u1007": "z",
        "\u1005\u103B": "za",
        "\u100A": "ny",
        "\u100B": "t",
        "\u100C": "ta",
        "\u100D": "d",
        "\u100E": "da",
        "\u100F": "na",
        "\u1010": "t",
        "\u1011": "ta",
        "\u1012": "d",
        "\u1013": "da",
        "\u1014": "n",
        "\u1015": "p",
        "\u1016": "pa",
        "\u1017": "b",
        "\u1018": "ba",
        "\u1019": "m",
        "\u101A": "y",
        "\u101B": "ya",
        "\u101C": "l",
        "\u101D": "w",
        "\u101E": "th",
        "\u101F": "h",
        "\u1020": "la",
        "\u1021": "a",
        // consonant character combos
        "\u103C": "y",
        "\u103B": "ya",
        "\u103D": "w",
        "\u103C\u103D": "yw",
        "\u103B\u103D": "ywa",
        "\u103E": "h",
        // independent vowels
        "\u1027": "e",
        "\u104F": "-e",
        "\u1023": "i",
        "\u1024": "-i",
        "\u1009": "u",
        "\u1026": "-u",
        "\u1029": "aw",
        "\u101E\u103C\u1031\u102C": "aw",
        "\u102A": "aw",
        // numbers
        "\u1040": "0",
        "\u1041": "1",
        "\u1042": "2",
        "\u1043": "3",
        "\u1044": "4",
        "\u1045": "5",
        "\u1046": "6",
        "\u1047": "7",
        "\u1048": "8",
        "\u1049": "9",
        // virama and tone marks which are silent in transliteration
        "\u1039": "",
        "\u1037": "",
        "\u1038": "",
        // Czech
        "\u010D": "c",
        "\u010F": "d",
        "\u011B": "e",
        "\u0148": "n",
        "\u0159": "r",
        "\u0161": "s",
        "\u0165": "t",
        "\u016F": "u",
        "\u017E": "z",
        "\u010C": "C",
        "\u010E": "D",
        "\u011A": "E",
        "\u0147": "N",
        "\u0158": "R",
        "\u0160": "S",
        "\u0164": "T",
        "\u016E": "U",
        "\u017D": "Z",
        // Dhivehi
        "\u0780": "h",
        "\u0781": "sh",
        "\u0782": "n",
        "\u0783": "r",
        "\u0784": "b",
        "\u0785": "lh",
        "\u0786": "k",
        "\u0787": "a",
        "\u0788": "v",
        "\u0789": "m",
        "\u078A": "f",
        "\u078B": "dh",
        "\u078C": "th",
        "\u078D": "l",
        "\u078E": "g",
        "\u078F": "gn",
        "\u0790": "s",
        "\u0791": "d",
        "\u0792": "z",
        "\u0793": "t",
        "\u0794": "y",
        "\u0795": "p",
        "\u0796": "j",
        "\u0797": "ch",
        "\u0798": "tt",
        "\u0799": "hh",
        "\u079A": "kh",
        "\u079B": "th",
        "\u079C": "z",
        "\u079D": "sh",
        "\u079E": "s",
        "\u079F": "d",
        "\u07A0": "t",
        "\u07A1": "z",
        "\u07A2": "a",
        "\u07A3": "gh",
        "\u07A4": "q",
        "\u07A5": "w",
        "\u07A6": "a",
        "\u07A7": "aa",
        "\u07A8": "i",
        "\u07A9": "ee",
        "\u07AA": "u",
        "\u07AB": "oo",
        "\u07AC": "e",
        "\u07AD": "ey",
        "\u07AE": "o",
        "\u07AF": "oa",
        "\u07B0": "",
        // Georgian https://en.wikipedia.org/wiki/Romanization_of_Georgian
        // National system (2002)
        "\u10D0": "a",
        "\u10D1": "b",
        "\u10D2": "g",
        "\u10D3": "d",
        "\u10D4": "e",
        "\u10D5": "v",
        "\u10D6": "z",
        "\u10D7": "t",
        "\u10D8": "i",
        "\u10D9": "k",
        "\u10DA": "l",
        "\u10DB": "m",
        "\u10DC": "n",
        "\u10DD": "o",
        "\u10DE": "p",
        "\u10DF": "zh",
        "\u10E0": "r",
        "\u10E1": "s",
        "\u10E2": "t",
        "\u10E3": "u",
        "\u10E4": "p",
        "\u10E5": "k",
        "\u10E6": "gh",
        "\u10E7": "q",
        "\u10E8": "sh",
        "\u10E9": "ch",
        "\u10EA": "ts",
        "\u10EB": "dz",
        "\u10EC": "ts",
        "\u10ED": "ch",
        "\u10EE": "kh",
        "\u10EF": "j",
        "\u10F0": "h",
        // Greek
        "\u03B1": "a",
        "\u03B2": "v",
        "\u03B3": "g",
        "\u03B4": "d",
        "\u03B5": "e",
        "\u03B6": "z",
        "\u03B7": "i",
        "\u03B8": "th",
        "\u03B9": "i",
        "\u03BA": "k",
        "\u03BB": "l",
        "\u03BC": "m",
        "\u03BD": "n",
        "\u03BE": "ks",
        "\u03BF": "o",
        "\u03C0": "p",
        "\u03C1": "r",
        "\u03C3": "s",
        "\u03C4": "t",
        "\u03C5": "y",
        "\u03C6": "f",
        "\u03C7": "x",
        "\u03C8": "ps",
        "\u03C9": "o",
        "\u03AC": "a",
        "\u03AD": "e",
        "\u03AF": "i",
        "\u03CC": "o",
        "\u03CD": "y",
        "\u03AE": "i",
        "\u03CE": "o",
        "\u03C2": "s",
        "\u03CA": "i",
        "\u03B0": "y",
        "\u03CB": "y",
        "\u0390": "i",
        "\u0391": "A",
        "\u0392": "B",
        "\u0393": "G",
        "\u0394": "D",
        "\u0395": "E",
        "\u0396": "Z",
        "\u0397": "I",
        "\u0398": "TH",
        "\u0399": "I",
        "\u039A": "K",
        "\u039B": "L",
        "\u039C": "M",
        "\u039D": "N",
        "\u039E": "KS",
        "\u039F": "O",
        "\u03A0": "P",
        "\u03A1": "R",
        "\u03A3": "S",
        "\u03A4": "T",
        "\u03A5": "Y",
        "\u03A6": "F",
        "\u03A7": "X",
        "\u03A8": "PS",
        "\u03A9": "O",
        "\u0386": "A",
        "\u0388": "E",
        "\u038A": "I",
        "\u038C": "O",
        "\u038E": "Y",
        "\u0389": "I",
        "\u038F": "O",
        "\u03AA": "I",
        "\u03AB": "Y",
        // Latvian
        "\u0101": "a",
        // 'č': 'c', // duplicate
        "\u0113": "e",
        "\u0123": "g",
        "\u012B": "i",
        "\u0137": "k",
        "\u013C": "l",
        "\u0146": "n",
        // 'š': 's', // duplicate
        "\u016B": "u",
        // 'ž': 'z', // duplicate
        "\u0100": "A",
        // 'Č': 'C', // duplicate
        "\u0112": "E",
        "\u0122": "G",
        "\u012A": "I",
        "\u0136": "k",
        "\u013B": "L",
        "\u0145": "N",
        // 'Š': 'S', // duplicate
        "\u016A": "U",
        // 'Ž': 'Z', // duplicate
        // Macedonian
        "\u040C": "Kj",
        "\u045C": "kj",
        "\u0409": "Lj",
        "\u0459": "lj",
        "\u040A": "Nj",
        "\u045A": "nj",
        "\u0422\u0441": "Ts",
        "\u0442\u0441": "ts",
        // Polish
        "\u0105": "a",
        "\u0107": "c",
        "\u0119": "e",
        "\u0142": "l",
        "\u0144": "n",
        // 'ó': 'o', // duplicate
        "\u015B": "s",
        "\u017A": "z",
        "\u017C": "z",
        "\u0104": "A",
        "\u0106": "C",
        "\u0118": "E",
        "\u0141": "L",
        "\u0143": "N",
        "\u015A": "S",
        "\u0179": "Z",
        "\u017B": "Z",
        // Ukranian
        "\u0404": "Ye",
        "\u0406": "I",
        "\u0407": "Yi",
        "\u0490": "G",
        "\u0454": "ye",
        "\u0456": "i",
        "\u0457": "yi",
        "\u0491": "g",
        // Romanian
        "\u0103": "a",
        "\u0102": "A",
        "\u0219": "s",
        "\u0218": "S",
        // 'ş': 's', // duplicate
        // 'Ş': 'S', // duplicate
        "\u021B": "t",
        "\u021A": "T",
        "\u0163": "t",
        "\u0162": "T",
        // Russian https://en.wikipedia.org/wiki/Romanization_of_Russian
        // ICAO
        "\u0430": "a",
        "\u0431": "b",
        "\u0432": "v",
        "\u0433": "g",
        "\u0434": "d",
        "\u0435": "e",
        "\u0451": "yo",
        "\u0436": "zh",
        "\u0437": "z",
        "\u0438": "i",
        "\u0439": "i",
        "\u043A": "k",
        "\u043B": "l",
        "\u043C": "m",
        "\u043D": "n",
        "\u043E": "o",
        "\u043F": "p",
        "\u0440": "r",
        "\u0441": "s",
        "\u0442": "t",
        "\u0443": "u",
        "\u0444": "f",
        "\u0445": "kh",
        "\u0446": "c",
        "\u0447": "ch",
        "\u0448": "sh",
        "\u0449": "sh",
        "\u044A": "",
        "\u044B": "y",
        "\u044C": "",
        "\u044D": "e",
        "\u044E": "yu",
        "\u044F": "ya",
        "\u0410": "A",
        "\u0411": "B",
        "\u0412": "V",
        "\u0413": "G",
        "\u0414": "D",
        "\u0415": "E",
        "\u0401": "Yo",
        "\u0416": "Zh",
        "\u0417": "Z",
        "\u0418": "I",
        "\u0419": "I",
        "\u041A": "K",
        "\u041B": "L",
        "\u041C": "M",
        "\u041D": "N",
        "\u041E": "O",
        "\u041F": "P",
        "\u0420": "R",
        "\u0421": "S",
        "\u0422": "T",
        "\u0423": "U",
        "\u0424": "F",
        "\u0425": "Kh",
        "\u0426": "C",
        "\u0427": "Ch",
        "\u0428": "Sh",
        "\u0429": "Sh",
        "\u042A": "",
        "\u042B": "Y",
        "\u042C": "",
        "\u042D": "E",
        "\u042E": "Yu",
        "\u042F": "Ya",
        // Serbian
        "\u0452": "dj",
        "\u0458": "j",
        // 'љ': 'lj',  // duplicate
        // 'њ': 'nj', // duplicate
        "\u045B": "c",
        "\u045F": "dz",
        "\u0402": "Dj",
        "\u0408": "j",
        // 'Љ': 'Lj', // duplicate
        // 'Њ': 'Nj', // duplicate
        "\u040B": "C",
        "\u040F": "Dz",
        // Slovak
        "\u013E": "l",
        "\u013A": "l",
        "\u0155": "r",
        "\u013D": "L",
        "\u0139": "L",
        "\u0154": "R",
        // Turkish
        "\u015F": "s",
        "\u015E": "S",
        "\u0131": "i",
        "\u0130": "I",
        // 'ç': 'c', // duplicate
        // 'Ç': 'C', // duplicate
        // 'ü': 'u', // duplicate, see langCharMap
        // 'Ü': 'U', // duplicate, see langCharMap
        // 'ö': 'o', // duplicate, see langCharMap
        // 'Ö': 'O', // duplicate, see langCharMap
        "\u011F": "g",
        "\u011E": "G",
        // Vietnamese
        "\u1EA3": "a",
        "\u1EA2": "A",
        "\u1EB3": "a",
        "\u1EB2": "A",
        "\u1EA9": "a",
        "\u1EA8": "A",
        "\u0111": "d",
        "\u0110": "D",
        "\u1EB9": "e",
        "\u1EB8": "E",
        "\u1EBD": "e",
        "\u1EBC": "E",
        "\u1EBB": "e",
        "\u1EBA": "E",
        "\u1EBF": "e",
        "\u1EBE": "E",
        "\u1EC1": "e",
        "\u1EC0": "E",
        "\u1EC7": "e",
        "\u1EC6": "E",
        "\u1EC5": "e",
        "\u1EC4": "E",
        "\u1EC3": "e",
        "\u1EC2": "E",
        "\u1ECF": "o",
        "\u1ECD": "o",
        "\u1ECC": "o",
        "\u1ED1": "o",
        "\u1ED0": "O",
        "\u1ED3": "o",
        "\u1ED2": "O",
        "\u1ED5": "o",
        "\u1ED4": "O",
        "\u1ED9": "o",
        "\u1ED8": "O",
        "\u1ED7": "o",
        "\u1ED6": "O",
        "\u01A1": "o",
        "\u01A0": "O",
        "\u1EDB": "o",
        "\u1EDA": "O",
        "\u1EDD": "o",
        "\u1EDC": "O",
        "\u1EE3": "o",
        "\u1EE2": "O",
        "\u1EE1": "o",
        "\u1EE0": "O",
        "\u1EDE": "o",
        "\u1EDF": "o",
        "\u1ECB": "i",
        "\u1ECA": "I",
        "\u0129": "i",
        "\u0128": "I",
        "\u1EC9": "i",
        "\u1EC8": "i",
        "\u1EE7": "u",
        "\u1EE6": "U",
        "\u1EE5": "u",
        "\u1EE4": "U",
        "\u0169": "u",
        "\u0168": "U",
        "\u01B0": "u",
        "\u01AF": "U",
        "\u1EE9": "u",
        "\u1EE8": "U",
        "\u1EEB": "u",
        "\u1EEA": "U",
        "\u1EF1": "u",
        "\u1EF0": "U",
        "\u1EEF": "u",
        "\u1EEE": "U",
        "\u1EED": "u",
        "\u1EEC": "\u01B0",
        "\u1EF7": "y",
        "\u1EF6": "y",
        "\u1EF3": "y",
        "\u1EF2": "Y",
        "\u1EF5": "y",
        "\u1EF4": "Y",
        "\u1EF9": "y",
        "\u1EF8": "Y",
        "\u1EA1": "a",
        "\u1EA0": "A",
        "\u1EA5": "a",
        "\u1EA4": "A",
        "\u1EA7": "a",
        "\u1EA6": "A",
        "\u1EAD": "a",
        "\u1EAC": "A",
        "\u1EAB": "a",
        "\u1EAA": "A",
        // 'ă': 'a', // duplicate
        // 'Ă': 'A', // duplicate
        "\u1EAF": "a",
        "\u1EAE": "A",
        "\u1EB1": "a",
        "\u1EB0": "A",
        "\u1EB7": "a",
        "\u1EB6": "A",
        "\u1EB5": "a",
        "\u1EB4": "A",
        "\u24EA": "0",
        "\u2460": "1",
        "\u2461": "2",
        "\u2462": "3",
        "\u2463": "4",
        "\u2464": "5",
        "\u2465": "6",
        "\u2466": "7",
        "\u2467": "8",
        "\u2468": "9",
        "\u2469": "10",
        "\u246A": "11",
        "\u246B": "12",
        "\u246C": "13",
        "\u246D": "14",
        "\u246E": "15",
        "\u246F": "16",
        "\u2470": "17",
        "\u2471": "18",
        "\u2472": "18",
        "\u2473": "18",
        "\u24F5": "1",
        "\u24F6": "2",
        "\u24F7": "3",
        "\u24F8": "4",
        "\u24F9": "5",
        "\u24FA": "6",
        "\u24FB": "7",
        "\u24FC": "8",
        "\u24FD": "9",
        "\u24FE": "10",
        "\u24FF": "0",
        "\u24EB": "11",
        "\u24EC": "12",
        "\u24ED": "13",
        "\u24EE": "14",
        "\u24EF": "15",
        "\u24F0": "16",
        "\u24F1": "17",
        "\u24F2": "18",
        "\u24F3": "19",
        "\u24F4": "20",
        "\u24B6": "A",
        "\u24B7": "B",
        "\u24B8": "C",
        "\u24B9": "D",
        "\u24BA": "E",
        "\u24BB": "F",
        "\u24BC": "G",
        "\u24BD": "H",
        "\u24BE": "I",
        "\u24BF": "J",
        "\u24C0": "K",
        "\u24C1": "L",
        "\u24C2": "M",
        "\u24C3": "N",
        "\u24C4": "O",
        "\u24C5": "P",
        "\u24C6": "Q",
        "\u24C7": "R",
        "\u24C8": "S",
        "\u24C9": "T",
        "\u24CA": "U",
        "\u24CB": "V",
        "\u24CC": "W",
        "\u24CD": "X",
        "\u24CE": "Y",
        "\u24CF": "Z",
        "\u24D0": "a",
        "\u24D1": "b",
        "\u24D2": "c",
        "\u24D3": "d",
        "\u24D4": "e",
        "\u24D5": "f",
        "\u24D6": "g",
        "\u24D7": "h",
        "\u24D8": "i",
        "\u24D9": "j",
        "\u24DA": "k",
        "\u24DB": "l",
        "\u24DC": "m",
        "\u24DD": "n",
        "\u24DE": "o",
        "\u24DF": "p",
        "\u24E0": "q",
        "\u24E1": "r",
        "\u24E2": "s",
        "\u24E3": "t",
        "\u24E4": "u",
        "\u24E6": "v",
        "\u24E5": "w",
        "\u24E7": "x",
        "\u24E8": "y",
        "\u24E9": "z",
        // symbols
        "\u201C": '"',
        "\u201D": '"',
        "\u2018": "'",
        "\u2019": "'",
        "\u2202": "d",
        "\u0192": "f",
        "\u2122": "(TM)",
        "\xA9": "(C)",
        "\u0153": "oe",
        "\u0152": "OE",
        "\xAE": "(R)",
        "\u2020": "+",
        "\u2120": "(SM)",
        "\u2026": "...",
        "\u02DA": "o",
        "\xBA": "o",
        "\xAA": "a",
        "\u2022": "*",
        "\u104A": ",",
        "\u104B": ".",
        // currency
        "$": "USD",
        "\u20AC": "EUR",
        "\u20A2": "BRN",
        "\u20A3": "FRF",
        "\xA3": "GBP",
        "\u20A4": "ITL",
        "\u20A6": "NGN",
        "\u20A7": "ESP",
        "\u20A9": "KRW",
        "\u20AA": "ILS",
        "\u20AB": "VND",
        "\u20AD": "LAK",
        "\u20AE": "MNT",
        "\u20AF": "GRD",
        "\u20B1": "ARS",
        "\u20B2": "PYG",
        "\u20B3": "ARA",
        "\u20B4": "UAH",
        "\u20B5": "GHS",
        "\xA2": "cent",
        "\xA5": "CNY",
        "\u5143": "CNY",
        "\u5186": "YEN",
        "\uFDFC": "IRR",
        "\u20A0": "EWE",
        "\u0E3F": "THB",
        "\u20A8": "INR",
        "\u20B9": "INR",
        "\u20B0": "PF",
        "\u20BA": "TRY",
        "\u060B": "AFN",
        "\u20BC": "AZN",
        "\u043B\u0432": "BGN",
        "\u17DB": "KHR",
        "\u20A1": "CRC",
        "\u20B8": "KZT",
        "\u0434\u0435\u043D": "MKD",
        "z\u0142": "PLN",
        "\u20BD": "RUB",
        "\u20BE": "GEL"
      };
      var lookAheadCharArray = [
        // burmese
        "\u103A",
        // Dhivehi
        "\u07B0"
      ];
      var diatricMap = {
        // Burmese
        // dependent vowels
        "\u102C": "a",
        "\u102B": "a",
        "\u1031": "e",
        "\u1032": "e",
        "\u102D": "i",
        "\u102E": "i",
        "\u102D\u102F": "o",
        "\u102F": "u",
        "\u1030": "u",
        "\u1031\u102B\u1004\u103A": "aung",
        "\u1031\u102C": "aw",
        "\u1031\u102C\u103A": "aw",
        "\u1031\u102B": "aw",
        "\u1031\u102B\u103A": "aw",
        "\u103A": "\u103A",
        // this is special case but the character will be converted to latin in the code
        "\u1000\u103A": "et",
        "\u102D\u102F\u1000\u103A": "aik",
        "\u1031\u102C\u1000\u103A": "auk",
        "\u1004\u103A": "in",
        "\u102D\u102F\u1004\u103A": "aing",
        "\u1031\u102C\u1004\u103A": "aung",
        "\u1005\u103A": "it",
        "\u100A\u103A": "i",
        "\u1010\u103A": "at",
        "\u102D\u1010\u103A": "eik",
        "\u102F\u1010\u103A": "ok",
        "\u103D\u1010\u103A": "ut",
        "\u1031\u1010\u103A": "it",
        "\u1012\u103A": "d",
        "\u102D\u102F\u1012\u103A": "ok",
        "\u102F\u1012\u103A": "ait",
        "\u1014\u103A": "an",
        "\u102C\u1014\u103A": "an",
        "\u102D\u1014\u103A": "ein",
        "\u102F\u1014\u103A": "on",
        "\u103D\u1014\u103A": "un",
        "\u1015\u103A": "at",
        "\u102D\u1015\u103A": "eik",
        "\u102F\u1015\u103A": "ok",
        "\u103D\u1015\u103A": "ut",
        "\u1014\u103A\u102F\u1015\u103A": "nub",
        "\u1019\u103A": "an",
        "\u102D\u1019\u103A": "ein",
        "\u102F\u1019\u103A": "on",
        "\u103D\u1019\u103A": "un",
        "\u101A\u103A": "e",
        "\u102D\u102F\u101C\u103A": "ol",
        "\u1009\u103A": "in",
        "\u1036": "an",
        "\u102D\u1036": "ein",
        "\u102F\u1036": "on",
        // Dhivehi
        "\u07A6\u0787\u07B0": "ah",
        "\u07A6\u0781\u07B0": "ah"
      };
      var langCharMap = {
        "en": {},
        // default language
        "az": {
          // Azerbaijani
          "\xE7": "c",
          "\u0259": "e",
          "\u011F": "g",
          "\u0131": "i",
          "\xF6": "o",
          "\u015F": "s",
          "\xFC": "u",
          "\xC7": "C",
          "\u018F": "E",
          "\u011E": "G",
          "\u0130": "I",
          "\xD6": "O",
          "\u015E": "S",
          "\xDC": "U"
        },
        "cs": {
          // Czech
          "\u010D": "c",
          "\u010F": "d",
          "\u011B": "e",
          "\u0148": "n",
          "\u0159": "r",
          "\u0161": "s",
          "\u0165": "t",
          "\u016F": "u",
          "\u017E": "z",
          "\u010C": "C",
          "\u010E": "D",
          "\u011A": "E",
          "\u0147": "N",
          "\u0158": "R",
          "\u0160": "S",
          "\u0164": "T",
          "\u016E": "U",
          "\u017D": "Z"
        },
        "fi": {
          // Finnish
          // 'å': 'a', duplicate see charMap/latin
          // 'Å': 'A', duplicate see charMap/latin
          "\xE4": "a",
          // ok
          "\xC4": "A",
          // ok
          "\xF6": "o",
          // ok
          "\xD6": "O"
          // ok
        },
        "hu": {
          // Hungarian
          "\xE4": "a",
          // ok
          "\xC4": "A",
          // ok
          // 'á': 'a', duplicate see charMap/latin
          // 'Á': 'A', duplicate see charMap/latin
          "\xF6": "o",
          // ok
          "\xD6": "O",
          // ok
          // 'ő': 'o', duplicate see charMap/latin
          // 'Ő': 'O', duplicate see charMap/latin
          "\xFC": "u",
          "\xDC": "U",
          "\u0171": "u",
          "\u0170": "U"
        },
        "lt": {
          // Lithuanian
          "\u0105": "a",
          "\u010D": "c",
          "\u0119": "e",
          "\u0117": "e",
          "\u012F": "i",
          "\u0161": "s",
          "\u0173": "u",
          "\u016B": "u",
          "\u017E": "z",
          "\u0104": "A",
          "\u010C": "C",
          "\u0118": "E",
          "\u0116": "E",
          "\u012E": "I",
          "\u0160": "S",
          "\u0172": "U",
          "\u016A": "U"
        },
        "lv": {
          // Latvian
          "\u0101": "a",
          "\u010D": "c",
          "\u0113": "e",
          "\u0123": "g",
          "\u012B": "i",
          "\u0137": "k",
          "\u013C": "l",
          "\u0146": "n",
          "\u0161": "s",
          "\u016B": "u",
          "\u017E": "z",
          "\u0100": "A",
          "\u010C": "C",
          "\u0112": "E",
          "\u0122": "G",
          "\u012A": "i",
          "\u0136": "k",
          "\u013B": "L",
          "\u0145": "N",
          "\u0160": "S",
          "\u016A": "u",
          "\u017D": "Z"
        },
        "pl": {
          // Polish
          "\u0105": "a",
          "\u0107": "c",
          "\u0119": "e",
          "\u0142": "l",
          "\u0144": "n",
          "\xF3": "o",
          "\u015B": "s",
          "\u017A": "z",
          "\u017C": "z",
          "\u0104": "A",
          "\u0106": "C",
          "\u0118": "e",
          "\u0141": "L",
          "\u0143": "N",
          "\xD3": "O",
          "\u015A": "S",
          "\u0179": "Z",
          "\u017B": "Z"
        },
        "sv": {
          // Swedish
          // 'å': 'a', duplicate see charMap/latin
          // 'Å': 'A', duplicate see charMap/latin
          "\xE4": "a",
          // ok
          "\xC4": "A",
          // ok
          "\xF6": "o",
          // ok
          "\xD6": "O"
          // ok
        },
        "sk": {
          // Slovak
          "\xE4": "a",
          "\xC4": "A"
        },
        "sr": {
          // Serbian
          "\u0459": "lj",
          "\u045A": "nj",
          "\u0409": "Lj",
          "\u040A": "Nj",
          "\u0111": "dj",
          "\u0110": "Dj"
        },
        "tr": {
          // Turkish
          "\xDC": "U",
          "\xD6": "O",
          "\xFC": "u",
          "\xF6": "o"
        }
      };
      var symbolMap = {
        "ar": {
          "\u2206": "delta",
          "\u221E": "la-nihaya",
          "\u2665": "hob",
          "&": "wa",
          "|": "aw",
          "<": "aqal-men",
          ">": "akbar-men",
          "\u2211": "majmou",
          "\xA4": "omla"
        },
        "az": {},
        "ca": {
          "\u2206": "delta",
          "\u221E": "infinit",
          "\u2665": "amor",
          "&": "i",
          "|": "o",
          "<": "menys que",
          ">": "mes que",
          "\u2211": "suma dels",
          "\xA4": "moneda"
        },
        "cs": {
          "\u2206": "delta",
          "\u221E": "nekonecno",
          "\u2665": "laska",
          "&": "a",
          "|": "nebo",
          "<": "mensi nez",
          ">": "vetsi nez",
          "\u2211": "soucet",
          "\xA4": "mena"
        },
        "de": {
          "\u2206": "delta",
          "\u221E": "unendlich",
          "\u2665": "Liebe",
          "&": "und",
          "|": "oder",
          "<": "kleiner als",
          ">": "groesser als",
          "\u2211": "Summe von",
          "\xA4": "Waehrung"
        },
        "dv": {
          "\u2206": "delta",
          "\u221E": "kolunulaa",
          "\u2665": "loabi",
          "&": "aai",
          "|": "noonee",
          "<": "ah vure kuda",
          ">": "ah vure bodu",
          "\u2211": "jumula",
          "\xA4": "faisaa"
        },
        "en": {
          "\u2206": "delta",
          "\u221E": "infinity",
          "\u2665": "love",
          "&": "and",
          "|": "or",
          "<": "less than",
          ">": "greater than",
          "\u2211": "sum",
          "\xA4": "currency"
        },
        "es": {
          "\u2206": "delta",
          "\u221E": "infinito",
          "\u2665": "amor",
          "&": "y",
          "|": "u",
          "<": "menos que",
          ">": "mas que",
          "\u2211": "suma de los",
          "\xA4": "moneda"
        },
        "fa": {
          "\u2206": "delta",
          "\u221E": "bi-nahayat",
          "\u2665": "eshgh",
          "&": "va",
          "|": "ya",
          "<": "kamtar-az",
          ">": "bishtar-az",
          "\u2211": "majmooe",
          "\xA4": "vahed"
        },
        "fi": {
          "\u2206": "delta",
          "\u221E": "aarettomyys",
          "\u2665": "rakkaus",
          "&": "ja",
          "|": "tai",
          "<": "pienempi kuin",
          ">": "suurempi kuin",
          "\u2211": "summa",
          "\xA4": "valuutta"
        },
        "fr": {
          "\u2206": "delta",
          "\u221E": "infiniment",
          "\u2665": "Amour",
          "&": "et",
          "|": "ou",
          "<": "moins que",
          ">": "superieure a",
          "\u2211": "somme des",
          "\xA4": "monnaie"
        },
        "ge": {
          "\u2206": "delta",
          "\u221E": "usasruloba",
          "\u2665": "siqvaruli",
          "&": "da",
          "|": "an",
          "<": "naklebi",
          ">": "meti",
          "\u2211": "jami",
          "\xA4": "valuta"
        },
        "gr": {},
        "hu": {
          "\u2206": "delta",
          "\u221E": "vegtelen",
          "\u2665": "szerelem",
          "&": "es",
          "|": "vagy",
          "<": "kisebb mint",
          ">": "nagyobb mint",
          "\u2211": "szumma",
          "\xA4": "penznem"
        },
        "it": {
          "\u2206": "delta",
          "\u221E": "infinito",
          "\u2665": "amore",
          "&": "e",
          "|": "o",
          "<": "minore di",
          ">": "maggiore di",
          "\u2211": "somma",
          "\xA4": "moneta"
        },
        "lt": {
          "\u2206": "delta",
          "\u221E": "begalybe",
          "\u2665": "meile",
          "&": "ir",
          "|": "ar",
          "<": "maziau nei",
          ">": "daugiau nei",
          "\u2211": "suma",
          "\xA4": "valiuta"
        },
        "lv": {
          "\u2206": "delta",
          "\u221E": "bezgaliba",
          "\u2665": "milestiba",
          "&": "un",
          "|": "vai",
          "<": "mazak neka",
          ">": "lielaks neka",
          "\u2211": "summa",
          "\xA4": "valuta"
        },
        "my": {
          "\u2206": "kwahkhyaet",
          "\u221E": "asaonasme",
          "\u2665": "akhyait",
          "&": "nhin",
          "|": "tho",
          "<": "ngethaw",
          ">": "kyithaw",
          "\u2211": "paungld",
          "\xA4": "ngwekye"
        },
        "mk": {},
        "nl": {
          "\u2206": "delta",
          "\u221E": "oneindig",
          "\u2665": "liefde",
          "&": "en",
          "|": "of",
          "<": "kleiner dan",
          ">": "groter dan",
          "\u2211": "som",
          "\xA4": "valuta"
        },
        "pl": {
          "\u2206": "delta",
          "\u221E": "nieskonczonosc",
          "\u2665": "milosc",
          "&": "i",
          "|": "lub",
          "<": "mniejsze niz",
          ">": "wieksze niz",
          "\u2211": "suma",
          "\xA4": "waluta"
        },
        "pt": {
          "\u2206": "delta",
          "\u221E": "infinito",
          "\u2665": "amor",
          "&": "e",
          "|": "ou",
          "<": "menor que",
          ">": "maior que",
          "\u2211": "soma",
          "\xA4": "moeda"
        },
        "ro": {
          "\u2206": "delta",
          "\u221E": "infinit",
          "\u2665": "dragoste",
          "&": "si",
          "|": "sau",
          "<": "mai mic ca",
          ">": "mai mare ca",
          "\u2211": "suma",
          "\xA4": "valuta"
        },
        "ru": {
          "\u2206": "delta",
          "\u221E": "beskonechno",
          "\u2665": "lubov",
          "&": "i",
          "|": "ili",
          "<": "menshe",
          ">": "bolshe",
          "\u2211": "summa",
          "\xA4": "valjuta"
        },
        "sk": {
          "\u2206": "delta",
          "\u221E": "nekonecno",
          "\u2665": "laska",
          "&": "a",
          "|": "alebo",
          "<": "menej ako",
          ">": "viac ako",
          "\u2211": "sucet",
          "\xA4": "mena"
        },
        "sr": {},
        "tr": {
          "\u2206": "delta",
          "\u221E": "sonsuzluk",
          "\u2665": "ask",
          "&": "ve",
          "|": "veya",
          "<": "kucuktur",
          ">": "buyuktur",
          "\u2211": "toplam",
          "\xA4": "para birimi"
        },
        "uk": {
          "\u2206": "delta",
          "\u221E": "bezkinechnist",
          "\u2665": "lubov",
          "&": "i",
          "|": "abo",
          "<": "menshe",
          ">": "bilshe",
          "\u2211": "suma",
          "\xA4": "valjuta"
        },
        "vn": {
          "\u2206": "delta",
          "\u221E": "vo cuc",
          "\u2665": "yeu",
          "&": "va",
          "|": "hoac",
          "<": "nho hon",
          ">": "lon hon",
          "\u2211": "tong",
          "\xA4": "tien te"
        }
      };
      var uricChars = [";", "?", ":", "@", "&", "=", "+", "$", ",", "/"].join("");
      var uricNoSlashChars = [";", "?", ":", "@", "&", "=", "+", "$", ","].join("");
      var markChars = [".", "!", "~", "*", "'", "(", ")"].join("");
      var getSlug = function getSlug2(input, opts) {
        var separator = "-";
        var result = "";
        var diatricString = "";
        var convertSymbols = true;
        var customReplacements = {};
        var maintainCase;
        var titleCase;
        var truncate;
        var uricFlag;
        var uricNoSlashFlag;
        var markFlag;
        var symbol;
        var langChar;
        var lucky;
        var i;
        var ch;
        var l;
        var lastCharWasSymbol;
        var lastCharWasDiatric;
        var allowedChars = "";
        if (typeof input !== "string") {
          return "";
        }
        if (typeof opts === "string") {
          separator = opts;
        }
        symbol = symbolMap.en;
        langChar = langCharMap.en;
        if (typeof opts === "object") {
          maintainCase = opts.maintainCase || false;
          customReplacements = opts.custom && typeof opts.custom === "object" ? opts.custom : customReplacements;
          truncate = +opts.truncate > 1 && opts.truncate || false;
          uricFlag = opts.uric || false;
          uricNoSlashFlag = opts.uricNoSlash || false;
          markFlag = opts.mark || false;
          convertSymbols = opts.symbols === false || opts.lang === false ? false : true;
          separator = opts.separator || separator;
          if (uricFlag) {
            allowedChars += uricChars;
          }
          if (uricNoSlashFlag) {
            allowedChars += uricNoSlashChars;
          }
          if (markFlag) {
            allowedChars += markChars;
          }
          symbol = opts.lang && symbolMap[opts.lang] && convertSymbols ? symbolMap[opts.lang] : convertSymbols ? symbolMap.en : {};
          langChar = opts.lang && langCharMap[opts.lang] ? langCharMap[opts.lang] : opts.lang === false || opts.lang === true ? {} : langCharMap.en;
          if (opts.titleCase && typeof opts.titleCase.length === "number" && Array.prototype.toString.call(opts.titleCase)) {
            opts.titleCase.forEach(function(v) {
              customReplacements[v + ""] = v + "";
            });
            titleCase = true;
          } else {
            titleCase = !!opts.titleCase;
          }
          if (opts.custom && typeof opts.custom.length === "number" && Array.prototype.toString.call(opts.custom)) {
            opts.custom.forEach(function(v) {
              customReplacements[v + ""] = v + "";
            });
          }
          Object.keys(customReplacements).forEach(function(v) {
            var r;
            if (v.length > 1) {
              r = new RegExp("\\b" + escapeChars(v) + "\\b", "gi");
            } else {
              r = new RegExp(escapeChars(v), "gi");
            }
            input = input.replace(r, customReplacements[v]);
          });
          for (ch in customReplacements) {
            allowedChars += ch;
          }
        }
        allowedChars += separator;
        allowedChars = escapeChars(allowedChars);
        input = input.replace(/(^\s+|\s+$)/g, "");
        lastCharWasSymbol = false;
        lastCharWasDiatric = false;
        for (i = 0, l = input.length; i < l; i++) {
          ch = input[i];
          if (isReplacedCustomChar(ch, customReplacements)) {
            lastCharWasSymbol = false;
          } else if (langChar[ch]) {
            ch = lastCharWasSymbol && langChar[ch].match(/[A-Za-z0-9]/) ? " " + langChar[ch] : langChar[ch];
            lastCharWasSymbol = false;
          } else if (ch in charMap) {
            if (i + 1 < l && lookAheadCharArray.indexOf(input[i + 1]) >= 0) {
              diatricString += ch;
              ch = "";
            } else if (lastCharWasDiatric === true) {
              ch = diatricMap[diatricString] + charMap[ch];
              diatricString = "";
            } else {
              ch = lastCharWasSymbol && charMap[ch].match(/[A-Za-z0-9]/) ? " " + charMap[ch] : charMap[ch];
            }
            lastCharWasSymbol = false;
            lastCharWasDiatric = false;
          } else if (ch in diatricMap) {
            diatricString += ch;
            ch = "";
            if (i === l - 1) {
              ch = diatricMap[diatricString];
            }
            lastCharWasDiatric = true;
          } else if (
            // process symbol chars
            symbol[ch] && !(uricFlag && uricChars.indexOf(ch) !== -1) && !(uricNoSlashFlag && uricNoSlashChars.indexOf(ch) !== -1)
          ) {
            ch = lastCharWasSymbol || result.substr(-1).match(/[A-Za-z0-9]/) ? separator + symbol[ch] : symbol[ch];
            ch += input[i + 1] !== void 0 && input[i + 1].match(/[A-Za-z0-9]/) ? separator : "";
            lastCharWasSymbol = true;
          } else {
            if (lastCharWasDiatric === true) {
              ch = diatricMap[diatricString] + ch;
              diatricString = "";
              lastCharWasDiatric = false;
            } else if (lastCharWasSymbol && (/[A-Za-z0-9]/.test(ch) || result.substr(-1).match(/A-Za-z0-9]/))) {
              ch = " " + ch;
            }
            lastCharWasSymbol = false;
          }
          result += ch.replace(new RegExp("[^\\w\\s" + allowedChars + "_-]", "g"), separator);
        }
        if (titleCase) {
          result = result.replace(/(\w)(\S*)/g, function(_, i2, r) {
            var j = i2.toUpperCase() + (r !== null ? r : "");
            return Object.keys(customReplacements).indexOf(j.toLowerCase()) < 0 ? j : j.toLowerCase();
          });
        }
        result = result.replace(/\s+/g, separator).replace(new RegExp("\\" + separator + "+", "g"), separator).replace(new RegExp("(^\\" + separator + "+|\\" + separator + "+$)", "g"), "");
        if (truncate && result.length > truncate) {
          lucky = result.charAt(truncate) === separator;
          result = result.slice(0, truncate);
          if (!lucky) {
            result = result.slice(0, result.lastIndexOf(separator));
          }
        }
        if (!maintainCase && !titleCase) {
          result = result.toLowerCase();
        }
        return result;
      };
      var createSlug = function createSlug2(opts) {
        return function getSlugWithConfig(input) {
          return getSlug(input, opts);
        };
      };
      var escapeChars = function escapeChars2(input) {
        return input.replace(/[-\\^$*+?.()|[\]{}\/]/g, "\\$&");
      };
      var isReplacedCustomChar = function(ch, customReplacements) {
        for (var c in customReplacements) {
          if (customReplacements[c] === ch) {
            return true;
          }
        }
      };
      if (typeof module !== "undefined" && module.exports) {
        module.exports = getSlug;
        module.exports.createSlug = createSlug;
      } else if (typeof define !== "undefined" && define.amd) {
        define([], function() {
          return getSlug;
        });
      } else {
        try {
          if (root.getSlug || root.createSlug) {
            throw "speakingurl: globals exists /(getSlug|createSlug)/";
          } else {
            root.getSlug = getSlug;
            root.createSlug = createSlug;
          }
        } catch (e) {
        }
      }
    })(exports);
  }
});

// ../../node_modules/.pnpm/speakingurl@14.0.1/node_modules/speakingurl/index.js
var require_speakingurl2 = __commonJS({
  "../../node_modules/.pnpm/speakingurl@14.0.1/node_modules/speakingurl/index.js"(exports, module) {
    "use strict";
    init_esm_shims();
    module.exports = require_speakingurl();
  }
});

// src/index.ts
init_esm_shims();

// src/core/index.ts
init_esm_shims();
import { isNuxtApp, target as target9 } from "@vue/devtools-shared";

// src/hook/index.ts
init_esm_shims();
import { target } from "@vue/devtools-shared";
import { createHooks } from "hookable";

// src/types/index.ts
init_esm_shims();

// src/types/app.ts
init_esm_shims();

// src/types/hook.ts
init_esm_shims();

// src/types/state.ts
init_esm_shims();

// src/types/context.ts
init_esm_shims();

// src/types/router.ts
init_esm_shims();

// src/types/env.ts
init_esm_shims();

// src/hook/index.ts
var _a, _b;
var devtoolsHooks = (_b = (_a = target).__VUE_DEVTOOLS_HOOK) != null ? _b : _a.__VUE_DEVTOOLS_HOOK = createHooks();
var on = {
  vueAppInit(fn) {
    devtoolsHooks.hook("app:init" /* APP_INIT */, fn);
  },
  vueAppUnmount(fn) {
    devtoolsHooks.hook("app:unmount" /* APP_UNMOUNT */, fn);
  },
  vueAppConnected(fn) {
    devtoolsHooks.hook("app:connected" /* APP_CONNECTED */, fn);
  },
  componentAdded(fn) {
    return devtoolsHooks.hook("component:added" /* COMPONENT_ADDED */, fn);
  },
  componentUpdated(fn) {
    return devtoolsHooks.hook("component:updated" /* COMPONENT_UPDATED */, fn);
  },
  componentRemoved(fn) {
    return devtoolsHooks.hook("component:removed" /* COMPONENT_REMOVED */, fn);
  },
  setupDevtoolsPlugin(fn) {
    devtoolsHooks.hook("devtools-plugin:setup" /* SETUP_DEVTOOLS_PLUGIN */, fn);
  }
};
function createDevToolsHook() {
  return {
    id: "vue-devtools-next",
    devtoolsVersion: "7.0",
    enabled: false,
    appRecords: [],
    apps: [],
    events: /* @__PURE__ */ new Map(),
    on(event, fn) {
      var _a10;
      if (!this.events.has(event))
        this.events.set(event, []);
      (_a10 = this.events.get(event)) == null ? void 0 : _a10.push(fn);
      return () => this.off(event, fn);
    },
    once(event, fn) {
      const onceFn = (...args) => {
        this.off(event, onceFn);
        fn(...args);
      };
      this.on(event, onceFn);
      return [event, onceFn];
    },
    off(event, fn) {
      if (this.events.has(event)) {
        const eventCallbacks = this.events.get(event);
        const index = eventCallbacks.indexOf(fn);
        if (index !== -1)
          eventCallbacks.splice(index, 1);
      }
    },
    emit(event, ...payload) {
      if (this.events.has(event))
        this.events.get(event).forEach((fn) => fn(...payload));
    }
  };
}
function subscribeDevToolsHook() {
  const hook2 = target.__VUE_DEVTOOLS_GLOBAL_HOOK__;
  hook2.on("app:init" /* APP_INIT */, (app, version) => {
    var _a10, _b10, _c;
    if ((_c = (_b10 = (_a10 = app == null ? void 0 : app._instance) == null ? void 0 : _a10.type) == null ? void 0 : _b10.devtools) == null ? void 0 : _c.hide)
      return;
    devtoolsHooks.callHook("app:init" /* APP_INIT */, app, version);
  });
  hook2.on("app:unmount" /* APP_UNMOUNT */, (app) => {
    devtoolsHooks.callHook("app:unmount" /* APP_UNMOUNT */, app);
  });
  hook2.on("component:added" /* COMPONENT_ADDED */, async (app, uid, parentUid, component) => {
    var _a10, _b10, _c;
    if ((_c = (_b10 = (_a10 = app == null ? void 0 : app._instance) == null ? void 0 : _a10.type) == null ? void 0 : _b10.devtools) == null ? void 0 : _c.hide)
      return;
    if (!app || typeof uid !== "number" && !uid || !component)
      return;
    devtoolsHooks.callHook("component:added" /* COMPONENT_ADDED */, app, uid, parentUid, component);
  });
  hook2.on("component:updated" /* COMPONENT_UPDATED */, (app, uid, parentUid, component) => {
    if (!app || typeof uid !== "number" && !uid || !component)
      return;
    devtoolsHooks.callHook("component:updated" /* COMPONENT_UPDATED */, app, uid, parentUid, component);
  });
  hook2.on("component:removed" /* COMPONENT_REMOVED */, async (app, uid, parentUid, component) => {
    if (!app || typeof uid !== "number" && !uid || !component)
      return;
    devtoolsHooks.callHook("component:removed" /* COMPONENT_REMOVED */, app, uid, parentUid, component);
  });
  hook2.on("devtools-plugin:setup" /* SETUP_DEVTOOLS_PLUGIN */, (pluginDescriptor, setupFn, options) => {
    if ((options == null ? void 0 : options.target) === "legacy")
      return;
    devtoolsHooks.callHook("devtools-plugin:setup" /* SETUP_DEVTOOLS_PLUGIN */, pluginDescriptor, setupFn);
  });
}
var hook = {
  on,
  setupDevToolsPlugin(pluginDescriptor, setupFn) {
    return devtoolsHooks.callHook("devtools-plugin:setup" /* SETUP_DEVTOOLS_PLUGIN */, pluginDescriptor, setupFn);
  }
};

// src/state/index.ts
init_esm_shims();

// src/state/state.ts
init_esm_shims();
import { target as global2 } from "@vue/devtools-shared";
import { debounce as debounce3 } from "perfect-debounce";

// src/api/index.ts
init_esm_shims();

// src/api/api.ts
init_esm_shims();

// src/core/timeline/index.ts
init_esm_shims();
function addTimelineLayer(payload) {
  devtoolsContext.timelineLayer.push(payload);
}

// src/core/inspector/index.ts
init_esm_shims();
function addInspector(payload) {
  devtoolsContext.inspector.push(payload);
}
function getInspector(inspectorId) {
  return devtoolsContext.inspector.find((inspector) => inspector.id === inspectorId);
}
function updateInspector(inspectorId, payload) {
  const inspector = getInspector(inspectorId);
  inspector && Object.assign(inspector, payload);
}

// src/core/app-record/index.ts
init_esm_shims();
var import_speakingurl = __toESM(require_speakingurl2(), 1);

// src/api/plugin.ts
init_esm_shims();

// src/core/router/index.ts
init_esm_shims();
import { deepClone, target as global } from "@vue/devtools-shared";
import { debounce } from "perfect-debounce";

// src/api/hook.ts
init_esm_shims();
import { target as target2 } from "@vue/devtools-shared";
import { createHooks as createHooks2 } from "hookable";
var _a2, _b2;
var apiHooks = (_b2 = (_a2 = target2).__VUE_DEVTOOLS_API_HOOK) != null ? _b2 : _a2.__VUE_DEVTOOLS_API_HOOK = createHooks2();
var instanceHooks = [];
var registerInstanceHook = (...args) => {
  const unregister = apiHooks.hook(...args);
  instanceHooks.push(unregister);
};

// src/core/router/index.ts
function getRoutes(router) {
  const routesMap = /* @__PURE__ */ new Map();
  return ((router == null ? void 0 : router.getRoutes()) || []).filter((i) => !routesMap.has(i.path) && routesMap.set(i.path, 1));
}
function filterRoutes(routes) {
  return routes.map((item) => {
    let { path, name, children } = item;
    if (children == null ? void 0 : children.length)
      children = filterRoutes(children);
    return {
      path,
      name,
      children
    };
  });
}
function filterCurrentRoute(route) {
  if (route) {
    const { fullPath, hash, href, path, name, matched, params, query } = route;
    return {
      fullPath,
      hash,
      href,
      path,
      name,
      params,
      query,
      matched: filterRoutes(matched)
    };
  }
  return route;
}
function normalizeRouterInfo(appRecord, state) {
  function init() {
    var _a10;
    const router = (_a10 = appRecord.app) == null ? void 0 : _a10.config.globalProperties.$router;
    const currentRoute = filterCurrentRoute(router == null ? void 0 : router.currentRoute.value);
    const routes = filterRoutes(getRoutes(router));
    const c = console.warn;
    console.warn = () => {
    };
    global[ROUTER_INFO_KEY] = {
      currentRoute: currentRoute ? deepClone(currentRoute) : {},
      routes: deepClone(routes)
    };
    global[ROUTER_KEY] = router;
    console.warn = c;
  }
  init();
  hook.on.componentUpdated(debounce(() => {
    var _a10;
    if (((_a10 = state.activeAppRecord) == null ? void 0 : _a10.app) !== appRecord.app)
      return;
    init();
    apiHooks.callHook("router-info:updated" /* ROUTER_INFO_UPDATED */, global[ROUTER_INFO_KEY]);
  }, 200));
}
function getRouterDevToolsId(id) {
  return id.replace(/\D/g, "") || "0";
}

// src/api/plugin.ts
function collectDevToolsPlugin(pluginDescriptor, setupFn) {
  devtoolsState.pluginBuffer.push([pluginDescriptor, setupFn]);
}
function setupDevToolsPlugin(pluginDescriptor, setupFn) {
  return hook.setupDevToolsPlugin(pluginDescriptor, setupFn);
}
function setupExternalPlugin(plugin, app, api) {
  const [pluginDescriptor, setupFn] = plugin;
  if (pluginDescriptor.app !== app)
    return;
  if (pluginDescriptor.packageName === "vue-router") {
    const id = getRouterDevToolsId(`${pluginDescriptor.id}`);
    if (pluginDescriptor.app === app) {
      devtoolsAppRecords.value = devtoolsAppRecords.value.map((item) => ({
        ...item,
        routerId: id
      }));
    }
  }
  const extendedApi = new Proxy(api, {
    get(target10, prop, receiver) {
      if (prop === "getSettings") {
        return function() {
          const _settings = {};
          Object.keys(pluginDescriptor.settings).forEach((key) => {
            _settings[key] = pluginDescriptor.settings[key].defaultValue;
          });
          return _settings;
        };
      }
      return Reflect.get(target10, prop, receiver);
    },
    set(target10, prop, value, receiver) {
      return Reflect.set(target10, prop, value, receiver);
    }
  });
  setupFn(extendedApi);
}
function updatePluginDetectives() {
  devtoolsAppRecords.value = devtoolsAppRecords.value.map((record) => {
    var _a10, _b10;
    const globalProperties = (_b10 = (_a10 = record.app) == null ? void 0 : _a10.config) == null ? void 0 : _b10.globalProperties;
    if (!globalProperties)
      return record;
    return {
      ...record,
      moduleDetectives: {
        vueQuery: !!getInspector("vue-query"),
        veeValidate: !!getInspector("vee-validate-inspector"),
        vueRouter: !!globalProperties.$router,
        pinia: !!globalProperties.$pinia,
        vueI18n: !!globalProperties.$i18n,
        vuex: !!globalProperties.$store
      }
    };
  });
}
function registerPlugin(app, api) {
  devtoolsState.pluginBuffer.forEach((plugin) => setupExternalPlugin(plugin, app, api));
  updatePluginDetectives();
}

// src/plugins/index.ts
init_esm_shims();

// src/plugins/component.ts
init_esm_shims();
import { debounce as debounce2 } from "perfect-debounce";

// src/core/component/utils/index.ts
init_esm_shims();
import { basename, classify } from "@vue/devtools-shared";
import { Fragment } from "vue";
function getComponentTypeName(options) {
  return options.name || options._componentTag || options.__VUE_DEVTOOLS_COMPONENT_GUSSED_NAME__ || options.__name;
}
function getComponentFileName(options) {
  const file = options.__file;
  if (file)
    return classify(basename(file, ".vue"));
}
function getComponentName(options) {
  const name = options.displayName || options.name || options._componentTag;
  if (name)
    return name;
  return getComponentFileName(options);
}
function saveComponentGussedName(instance, name) {
  instance.type.__VUE_DEVTOOLS_COMPONENT_GUSSED_NAME__ = name;
  return name;
}
function getAppRecord(instance) {
  if (instance.__VUE_DEVTOOLS_NEXT_APP_RECORD__)
    return instance.__VUE_DEVTOOLS_NEXT_APP_RECORD__;
  else if (instance.root)
    return instance.appContext.app.__VUE_DEVTOOLS_NEXT_APP_RECORD__;
}
async function getComponentId(options) {
  const { app, uid, instance } = options;
  try {
    if (instance.__VUE_DEVTOOLS_NEXT_UID__)
      return instance.__VUE_DEVTOOLS_NEXT_UID__;
    const appRecord = await getAppRecord(app);
    if (!appRecord)
      return null;
    const isRoot = appRecord.rootInstance === instance;
    return `${appRecord.id}:${isRoot ? "root" : uid}`;
  } catch (e) {
  }
}
function isFragment(instance) {
  var _a10;
  const subTreeType = (_a10 = instance.subTree) == null ? void 0 : _a10.type;
  return subTreeType === Fragment;
}
function isBeingDestroyed(instance) {
  return instance._isBeingDestroyed || instance.isUnmounted;
}
function getInstanceName(instance) {
  var _a10, _b10, _c;
  const name = getComponentTypeName(instance.type || {});
  if (name)
    return name;
  if (instance.root === instance)
    return "Root";
  for (const key in (_b10 = (_a10 = instance.parent) == null ? void 0 : _a10.type) == null ? void 0 : _b10.components) {
    if (instance.parent.type.components[key] === instance.type)
      return saveComponentGussedName(instance, key);
  }
  for (const key in (_c = instance.appContext) == null ? void 0 : _c.components) {
    if (instance.appContext.components[key] === instance.type)
      return saveComponentGussedName(instance, key);
  }
  const fileName = getComponentFileName(instance.type || {});
  if (fileName)
    return fileName;
  return "Anonymous Component";
}
function getUniqueComponentId(instance) {
  var _a10, _b10, _c;
  const appId = (_c = (_b10 = (_a10 = instance == null ? void 0 : instance.appContext) == null ? void 0 : _a10.app) == null ? void 0 : _b10.__VUE_DEVTOOLS_NEXT_APP_RECORD_ID__) != null ? _c : 0;
  const instanceId = instance === (instance == null ? void 0 : instance.root) ? "root" : instance.uid;
  return `${appId}:${instanceId}`;
}
function getRenderKey(value) {
  if (value == null)
    return "";
  if (typeof value === "number")
    return value;
  else if (typeof value === "string")
    return `'${value}'`;
  else if (Array.isArray(value))
    return "Array";
  else
    return "Object";
}
function returnError(cb) {
  try {
    return cb();
  } catch (e) {
    return e;
  }
}
function getComponentInstance(appRecord, instanceId) {
  instanceId = instanceId || `${appRecord.id}:root`;
  const instance = appRecord.instanceMap.get(instanceId);
  return instance || appRecord.instanceMap.get(":root");
}

// src/core/component/state/bounding-rect.ts
init_esm_shims();
function createRect() {
  const rect = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    get width() {
      return rect.right - rect.left;
    },
    get height() {
      return rect.bottom - rect.top;
    }
  };
  return rect;
}
var range;
function getTextRect(node) {
  if (!range)
    range = document.createRange();
  range.selectNode(node);
  return range.getBoundingClientRect();
}
function getFragmentRect(vnode) {
  const rect = createRect();
  if (!vnode.children)
    return rect;
  for (let i = 0, l = vnode.children.length; i < l; i++) {
    const childVnode = vnode.children[i];
    let childRect;
    if (childVnode.component) {
      childRect = getComponentBoundingRect(childVnode.component);
    } else if (childVnode.el) {
      const el = childVnode.el;
      if (el.nodeType === 1 || el.getBoundingClientRect)
        childRect = el.getBoundingClientRect();
      else if (el.nodeType === 3 && el.data.trim())
        childRect = getTextRect(el);
    }
    if (childRect)
      mergeRects(rect, childRect);
  }
  return rect;
}
function mergeRects(a, b) {
  if (!a.top || b.top < a.top)
    a.top = b.top;
  if (!a.bottom || b.bottom > a.bottom)
    a.bottom = b.bottom;
  if (!a.left || b.left < a.left)
    a.left = b.left;
  if (!a.right || b.right > a.right)
    a.right = b.right;
  return a;
}
var DEFAULT_RECT = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: 0,
  height: 0
};
function getComponentBoundingRect(instance) {
  const el = instance.subTree.el;
  if (typeof window === "undefined") {
    return DEFAULT_RECT;
  }
  if (isFragment(instance))
    return getFragmentRect(instance.subTree);
  else if ((el == null ? void 0 : el.nodeType) === 1)
    return el == null ? void 0 : el.getBoundingClientRect();
  else if (instance.subTree.component)
    return getComponentBoundingRect(instance.subTree.component);
  else
    return DEFAULT_RECT;
}

// src/core/component/tree/walker.ts
init_esm_shims();

// src/core/component/tree/el.ts
init_esm_shims();
function getRootElementsFromComponentInstance(instance) {
  if (isFragment(instance))
    return getFragmentRootElements(instance.subTree);
  if (!instance.subTree)
    return [];
  return [instance.subTree.el];
}
function getFragmentRootElements(vnode) {
  if (!vnode.children)
    return [];
  const list = [];
  vnode.children.forEach((childVnode) => {
    if (childVnode.component)
      list.push(...getRootElementsFromComponentInstance(childVnode.component));
    else if (childVnode == null ? void 0 : childVnode.el)
      list.push(childVnode.el);
  });
  return list;
}

// src/core/component/tree/filter.ts
init_esm_shims();
import { classify as classify2, kebabize } from "@vue/devtools-shared";
var ComponentFilter = class {
  constructor(filter) {
    this.filter = filter || "";
  }
  /**
   * Check if an instance is qualified.
   *
   * @param {Vue|Vnode} instance
   * @return {boolean}
   */
  isQualified(instance) {
    const name = getInstanceName(instance);
    return classify2(name).toLowerCase().includes(this.filter) || kebabize(name).toLowerCase().includes(this.filter);
  }
};
function createComponentFilter(filterText) {
  return new ComponentFilter(filterText);
}

// src/core/component/tree/walker.ts
var ComponentWalker = class {
  constructor(options) {
    // Dedupe instances (Some instances may be both on a component and on a child abstract/functional component)
    this.captureIds = /* @__PURE__ */ new Map();
    const { filterText = "", maxDepth, recursively } = options;
    this.componentFilter = createComponentFilter(filterText);
    this.maxDepth = maxDepth;
    this.recursively = recursively;
  }
  getComponentTree(instance) {
    this.captureIds = /* @__PURE__ */ new Map();
    return this.findQualifiedChildren(instance, 0);
  }
  getComponentParents(instance) {
    this.captureIds = /* @__PURE__ */ new Map();
    const parents = [];
    this.captureId(instance);
    let parent = instance;
    while (parent = parent.parent) {
      this.captureId(parent);
      parents.push(parent);
    }
    return parents;
  }
  captureId(instance) {
    if (!instance)
      return null;
    const id = instance.__VUE_DEVTOOLS_NEXT_UID__ != null ? instance.__VUE_DEVTOOLS_NEXT_UID__ : getUniqueComponentId(instance);
    instance.__VUE_DEVTOOLS_NEXT_UID__ = id;
    if (this.captureIds.has(id))
      return null;
    else
      this.captureIds.set(id, void 0);
    this.mark(instance);
    return id;
  }
  /**
   * Capture the meta information of an instance. (recursive)
   *
   * @param {Vue} instance
   * @return {object}
   */
  async capture(instance, depth) {
    var _a10;
    if (!instance)
      return null;
    const id = this.captureId(instance);
    const name = getInstanceName(instance);
    const children = this.getInternalInstanceChildren(instance.subTree).filter((child) => !isBeingDestroyed(child));
    const parents = this.getComponentParents(instance) || [];
    const inactive = !!instance.isDeactivated || parents.some((parent) => parent.isDeactivated);
    const treeNode = {
      uid: instance.uid,
      id,
      name,
      renderKey: getRenderKey(instance.vnode ? instance.vnode.key : null),
      inactive,
      children: [],
      isFragment: isFragment(instance),
      tags: typeof instance.type !== "function" ? [] : [
        {
          label: "functional",
          textColor: 5592405,
          backgroundColor: 15658734
        }
      ],
      autoOpen: this.recursively,
      file: instance.type.__file || ""
    };
    if (depth < this.maxDepth || instance.type.__isKeepAlive || parents.some((parent) => parent.type.__isKeepAlive)) {
      treeNode.children = await Promise.all(children.map((child) => this.capture(child, depth + 1)).filter(Boolean));
    }
    if (this.isKeepAlive(instance)) {
      const cachedComponents = this.getKeepAliveCachedInstances(instance);
      const childrenIds = children.map((child) => child.__VUE_DEVTOOLS_NEXT_UID__);
      for (const cachedChild of cachedComponents) {
        if (!childrenIds.includes(cachedChild.__VUE_DEVTOOLS_NEXT_UID__)) {
          const node = await this.capture({ ...cachedChild, isDeactivated: true }, depth + 1);
          if (node)
            treeNode.children.push(node);
        }
      }
    }
    const rootElements = getRootElementsFromComponentInstance(instance);
    const firstElement = rootElements[0];
    if (firstElement == null ? void 0 : firstElement.parentElement) {
      const parentInstance = instance.parent;
      const parentRootElements = parentInstance ? getRootElementsFromComponentInstance(parentInstance) : [];
      let el = firstElement;
      const indexList = [];
      do {
        indexList.push(Array.from(el.parentElement.childNodes).indexOf(el));
        el = el.parentElement;
      } while (el.parentElement && parentRootElements.length && !parentRootElements.includes(el));
      treeNode.domOrder = indexList.reverse();
    } else {
      treeNode.domOrder = [-1];
    }
    if ((_a10 = instance.suspense) == null ? void 0 : _a10.suspenseKey) {
      treeNode.tags.push({
        label: instance.suspense.suspenseKey,
        backgroundColor: 14979812,
        textColor: 16777215
      });
      this.mark(instance, true);
    }
    devtoolsContext.api.visitComponentTree({
      treeNode,
      componentInstance: instance,
      app: instance.appContext.app,
      filter: this.componentFilter.filter
    });
    return treeNode;
  }
  /**
   * Find qualified children from a single instance.
   * If the instance itself is qualified, just return itself.
   * This is ok because [].concat works in both cases.
   *
   * @param {Vue|Vnode} instance
   * @return {Vue|Array}
   */
  async findQualifiedChildren(instance, depth) {
    var _a10;
    if (this.componentFilter.isQualified(instance) && !((_a10 = instance.type.devtools) == null ? void 0 : _a10.hide)) {
      return [await this.capture(instance, depth)];
    } else if (instance.subTree) {
      const list = this.isKeepAlive(instance) ? this.getKeepAliveCachedInstances(instance) : this.getInternalInstanceChildren(instance.subTree);
      return this.findQualifiedChildrenFromList(list, depth);
    } else {
      return [];
    }
  }
  /**
   * Iterate through an array of instances and flatten it into
   * an array of qualified instances. This is a depth-first
   * traversal - e.g. if an instance is not matched, we will
   * recursively go deeper until a qualified child is found.
   *
   * @param {Array} instances
   * @return {Array}
   */
  async findQualifiedChildrenFromList(instances, depth) {
    instances = instances.filter((child) => {
      var _a10;
      return !isBeingDestroyed(child) && !((_a10 = child.type.devtools) == null ? void 0 : _a10.hide);
    });
    if (!this.componentFilter.filter)
      return Promise.all(instances.map((child) => this.capture(child, depth)));
    else
      return Array.prototype.concat.apply([], await Promise.all(instances.map((i) => this.findQualifiedChildren(i, depth))));
  }
  /**
   * Get children from a component instance.
   */
  getInternalInstanceChildren(subTree, suspense = null) {
    const list = [];
    if (subTree) {
      if (subTree.component) {
        !suspense ? list.push(subTree.component) : list.push({ ...subTree.component, suspense });
      } else if (subTree.suspense) {
        const suspenseKey = !subTree.suspense.isInFallback ? "suspense default" : "suspense fallback";
        list.push(...this.getInternalInstanceChildren(subTree.suspense.activeBranch, { ...subTree.suspense, suspenseKey }));
      } else if (Array.isArray(subTree.children)) {
        subTree.children.forEach((childSubTree) => {
          if (childSubTree.component)
            !suspense ? list.push(childSubTree.component) : list.push({ ...childSubTree.component, suspense });
          else
            list.push(...this.getInternalInstanceChildren(childSubTree, suspense));
        });
      }
    }
    return list.filter((child) => {
      var _a10;
      return !isBeingDestroyed(child) && !((_a10 = child.type.devtools) == null ? void 0 : _a10.hide);
    });
  }
  /**
   * Mark an instance as captured and store it in the instance map.
   *
   * @param {Vue} instance
   */
  mark(instance, force = false) {
    const instanceMap = getAppRecord(instance).instanceMap;
    if (force || !instanceMap.has(instance.__VUE_DEVTOOLS_NEXT_UID__)) {
      instanceMap.set(instance.__VUE_DEVTOOLS_NEXT_UID__, instance);
      devtoolsAppRecords.active.instanceMap = instanceMap;
    }
  }
  isKeepAlive(instance) {
    return instance.type.__isKeepAlive && instance.__v_cache;
  }
  getKeepAliveCachedInstances(instance) {
    return Array.from(instance.__v_cache.values()).map((vnode) => vnode.component).filter(Boolean);
  }
};

// src/core/component/state/editor.ts
init_esm_shims();
import { isReactive, isRef, toRaw } from "vue";
var StateEditor = class {
  constructor() {
    this.refEditor = new RefStateEditor();
  }
  set(object, path, value, cb) {
    const sections = Array.isArray(path) ? path : path.split(".");
    const markRef = false;
    while (sections.length > 1) {
      const section = sections.shift();
      if (object instanceof Map)
        object = object.get(section);
      if (object instanceof Set)
        object = Array.from(object.values())[section];
      else
        object = object[section];
      if (this.refEditor.isRef(object))
        object = this.refEditor.get(object);
    }
    const field = sections[0];
    const item = this.refEditor.get(object)[field];
    if (cb) {
      cb(object, field, value);
    } else {
      if (this.refEditor.isRef(item))
        this.refEditor.set(item, value);
      else if (markRef)
        object[field] = value;
      else
        object[field] = value;
    }
  }
  get(object, path) {
    const sections = Array.isArray(path) ? path : path.split(".");
    for (let i = 0; i < sections.length; i++) {
      if (object instanceof Map)
        object = object.get(sections[i]);
      else
        object = object[sections[i]];
      if (this.refEditor.isRef(object))
        object = this.refEditor.get(object);
      if (!object)
        return void 0;
    }
    return object;
  }
  has(object, path, parent = false) {
    if (typeof object === "undefined")
      return false;
    const sections = Array.isArray(path) ? path.slice() : path.split(".");
    const size = !parent ? 1 : 2;
    while (object && sections.length > size) {
      const section = sections.shift();
      object = object[section];
      if (this.refEditor.isRef(object))
        object = this.refEditor.get(object);
    }
    return object != null && Object.prototype.hasOwnProperty.call(object, sections[0]);
  }
  createDefaultSetCallback(state) {
    return (object, field, value) => {
      if (state.remove || state.newKey) {
        if (Array.isArray(object))
          object.splice(field, 1);
        else if (toRaw(object) instanceof Map)
          object.delete(field);
        else if (toRaw(object) instanceof Set)
          object.delete(Array.from(object.values())[field]);
        else
          Reflect.deleteProperty(object, field);
      }
      if (!state.remove) {
        const target10 = object[state.newKey || field];
        if (this.refEditor.isRef(target10))
          this.refEditor.set(target10, value);
        else if (toRaw(object) instanceof Map)
          object.set(state.newKey || field, value);
        else if (toRaw(object) instanceof Set)
          object.add(value);
        else
          object[state.newKey || field] = value;
      }
    };
  }
};
var RefStateEditor = class {
  set(ref, value) {
    if (isRef(ref)) {
      ref.value = value;
    } else {
      if (ref instanceof Set && Array.isArray(value)) {
        ref.clear();
        value.forEach((v) => ref.add(v));
        return;
      }
      const currentKeys = Object.keys(value);
      if (ref instanceof Map) {
        const previousKeysSet2 = new Set(ref.keys());
        currentKeys.forEach((key) => {
          ref.set(key, Reflect.get(value, key));
          previousKeysSet2.delete(key);
        });
        previousKeysSet2.forEach((key) => ref.delete(key));
        return;
      }
      const previousKeysSet = new Set(Object.keys(ref));
      currentKeys.forEach((key) => {
        Reflect.set(ref, key, Reflect.get(value, key));
        previousKeysSet.delete(key);
      });
      previousKeysSet.forEach((key) => Reflect.deleteProperty(ref, key));
    }
  }
  get(ref) {
    return isRef(ref) ? ref.value : ref;
  }
  isRef(ref) {
    return isRef(ref) || isReactive(ref);
  }
};
async function editComponentState(payload, stateEditor2) {
  const { path, nodeId, state, type } = payload;
  const instance = getComponentInstance(devtoolsContext.appRecord, nodeId);
  if (!instance)
    return;
  const targetPath = path.slice();
  let target10;
  if (instance.devtoolsRawSetupState && Object.keys(instance.devtoolsRawSetupState).includes(path[0])) {
    target10 = instance.devtoolsRawSetupState;
  }
  if (target10 && targetPath) {
    if (state.type === "object" && type === "reactive") {
    }
    stateEditor2.set(target10, targetPath, state.value, stateEditor2.createDefaultSetCallback(state));
  }
}
var stateEditor = new StateEditor();
async function editState(payload) {
  editComponentState(payload, stateEditor);
}

// src/core/component/state/index.ts
init_esm_shims();

// src/core/component/state/process.ts
init_esm_shims();
import { camelize } from "@vue/devtools-shared";

// src/core/component/state/constants.ts
init_esm_shims();
var vueBuiltins = /* @__PURE__ */ new Set([
  "nextTick",
  "defineComponent",
  "defineAsyncComponent",
  "defineCustomElement",
  "ref",
  "computed",
  "reactive",
  "readonly",
  "watchEffect",
  "watchPostEffect",
  "watchSyncEffect",
  "watch",
  "isRef",
  "unref",
  "toRef",
  "toRefs",
  "isProxy",
  "isReactive",
  "isReadonly",
  "shallowRef",
  "triggerRef",
  "customRef",
  "shallowReactive",
  "shallowReadonly",
  "toRaw",
  "markRaw",
  "effectScope",
  "getCurrentScope",
  "onScopeDispose",
  "onMounted",
  "onUpdated",
  "onUnmounted",
  "onBeforeMount",
  "onBeforeUpdate",
  "onBeforeUnmount",
  "onErrorCaptured",
  "onRenderTracked",
  "onRenderTriggered",
  "onActivated",
  "onDeactivated",
  "onServerPrefetch",
  "provide",
  "inject",
  "h",
  "mergeProps",
  "cloneVNode",
  "isVNode",
  "resolveComponent",
  "resolveDirective",
  "withDirectives",
  "withModifiers"
]);
var symbolRE = /^\[native Symbol Symbol\((.*)\)\]$/;
var rawTypeRE = /^\[object (\w+)\]$/;
var specialTypeRE = /^\[native (\w+) (.*?)(<>(([\s\S])*))?\]$/;
var fnTypeRE = /^(?:function|class) (\w+)/;
var MAX_STRING_SIZE = 1e4;
var MAX_ARRAY_SIZE = 5e3;
var UNDEFINED = "__vue_devtool_undefined__";
var INFINITY = "__vue_devtool_infinity__";
var NEGATIVE_INFINITY = "__vue_devtool_negative_infinity__";
var NAN = "__vue_devtool_nan__";
var ESC = {
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "&": "&amp;"
};

// src/core/component/state/util.ts
init_esm_shims();

// src/core/component/state/is.ts
init_esm_shims();
function isVueInstance(value) {
  return value._ && Object.keys(value._).includes("vnode");
}
function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}
function isPrimitive(data) {
  if (data == null)
    return true;
  const type = typeof data;
  return type === "string" || type === "number" || type === "boolean";
}
function isRef2(raw) {
  return !!raw.__v_isRef;
}
function isComputed(raw) {
  return isRef2(raw) && !!raw.effect;
}
function isReactive2(raw) {
  return !!raw.__v_isReactive;
}
function isReadOnly(raw) {
  return !!raw.__v_isReadonly;
}

// src/core/component/state/util.ts
var tokenMap = {
  [UNDEFINED]: "undefined",
  [NAN]: "NaN",
  [INFINITY]: "Infinity",
  [NEGATIVE_INFINITY]: "-Infinity"
};
var reversedTokenMap = Object.entries(tokenMap).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});
function internalStateTokenToString(value) {
  if (value === null)
    return "null";
  return typeof value === "string" && tokenMap[value] || false;
}
function replaceTokenToString(value) {
  const replaceRegex = new RegExp(`"(${Object.keys(tokenMap).join("|")})"`, "g");
  return value.replace(replaceRegex, (_, g1) => tokenMap[g1]);
}
function replaceStringToToken(value) {
  const literalValue = reversedTokenMap[value.trim()];
  if (literalValue)
    return `"${literalValue}"`;
  const replaceRegex = new RegExp(`:\\s*(${Object.keys(reversedTokenMap).join("|")})`, "g");
  return value.replace(replaceRegex, (_, g1) => `:"${reversedTokenMap[g1]}"`);
}
function getPropType(type) {
  if (Array.isArray(type))
    return type.map((t) => getPropType(t)).join(" or ");
  if (type == null)
    return "null";
  const match = type.toString().match(fnTypeRE);
  return typeof type === "function" ? match && match[1] || "any" : "any";
}
function sanitize(data) {
  if (!isPrimitive(data) && !Array.isArray(data) && !isPlainObject(data)) {
    return Object.prototype.toString.call(data);
  } else {
    return data;
  }
}
function getSetupStateType(raw) {
  return {
    ref: isRef2(raw),
    computed: isComputed(raw),
    reactive: isReactive2(raw),
    readonly: isReadOnly(raw)
  };
}
function toRaw2(value) {
  if (value == null ? void 0 : value.__v_raw)
    return value.__v_raw;
  return value;
}
function escape(s) {
  return s.replace(/[<>"&]/g, (s2) => {
    return ESC[s2] || s2;
  });
}

// src/core/component/state/process.ts
function mergeOptions(to, from, instance) {
  if (typeof from === "function")
    from = from.options;
  if (!from)
    return to;
  const { mixins, extends: extendsOptions } = from;
  extendsOptions && mergeOptions(to, extendsOptions, instance);
  mixins && mixins.forEach(
    (m) => mergeOptions(to, m, instance)
  );
  for (const key of ["computed", "inject"]) {
    if (Object.prototype.hasOwnProperty.call(from, key)) {
      if (!to[key])
        to[key] = from[key];
      else
        Object.assign(to[key], from[key]);
    }
  }
  return to;
}
function resolveMergedOptions(instance) {
  const raw = instance.type;
  const { mixins, extends: extendsOptions } = raw;
  const globalMixins = instance.appContext.mixins;
  if (!globalMixins.length && !mixins && !extendsOptions)
    return raw;
  const options = {};
  globalMixins.forEach((m) => mergeOptions(options, m, instance));
  mergeOptions(options, raw, instance);
  return options;
}
function processProps(instance) {
  const props = [];
  const propDefinitions = instance.type.props;
  for (const key in instance.props) {
    const propDefinition = propDefinitions ? propDefinitions[key] : null;
    const camelizeKey = camelize(key);
    props.push({
      type: "props",
      key: camelizeKey,
      value: returnError(() => instance.props[key]),
      meta: propDefinition ? {
        type: propDefinition.type ? getPropType(propDefinition.type) : "any",
        required: !!propDefinition.required,
        ...propDefinition.default ? {
          default: propDefinition.default.toString()
        } : {}
      } : { type: "invalid" }
    });
  }
  return props;
}
function processState(instance) {
  const type = instance.type;
  const props = type.props;
  const getters = type.vuex && type.vuex.getters;
  const computedDefs = type.computed;
  const data = {
    ...instance.data,
    ...instance.renderContext
  };
  return Object.keys(data).filter((key) => !(props && key in props) && !(getters && key in getters) && !(computedDefs && key in computedDefs)).map((key) => ({
    key,
    type: "data",
    value: returnError(() => data[key]),
    editable: true
  }));
}
function getStateTypeAndName(info) {
  const stateType = info.computed ? "computed" : info.ref ? "ref" : info.reactive ? "reactive" : null;
  const stateTypeName = stateType ? `${stateType.charAt(0).toUpperCase()}${stateType.slice(1)}` : null;
  return {
    stateType,
    stateTypeName
  };
}
function processSetupState(instance) {
  const raw = instance.devtoolsRawSetupState || {};
  return Object.keys(instance.setupState).filter((key) => !vueBuiltins.has(key) && key.split(/(?=[A-Z])/)[0] !== "use").map((key) => {
    var _a10, _b10, _c, _d;
    const value = returnError(() => toRaw2(instance.setupState[key]));
    const rawData = raw[key];
    let result;
    let isOtherType = typeof value === "function" || typeof (value == null ? void 0 : value.render) === "function" || typeof (value == null ? void 0 : value.__asyncLoader) === "function" || typeof value === "object" && value && ("setup" in value || "props" in value) || /^v[A-Z]/.test(key);
    if (rawData) {
      const info = getSetupStateType(rawData);
      const { stateType, stateTypeName } = getStateTypeAndName(info);
      const isState = info.ref || info.computed || info.reactive;
      const raw2 = ((_b10 = (_a10 = rawData.effect) == null ? void 0 : _a10.raw) == null ? void 0 : _b10.toString()) || ((_d = (_c = rawData.effect) == null ? void 0 : _c.fn) == null ? void 0 : _d.toString());
      if (stateType)
        isOtherType = false;
      result = {
        ...stateType ? { stateType, stateTypeName } : {},
        ...raw2 ? { raw: raw2 } : {},
        editable: isState && !info.readonly
      };
    }
    const type = isOtherType ? "setup (other)" : "setup";
    return {
      key,
      value,
      type,
      // @ts-expect-error ignore
      ...result
    };
  });
}
function processComputed(instance, mergedType) {
  const type = mergedType;
  const computed = [];
  const defs = type.computed || {};
  for (const key in defs) {
    const def = defs[key];
    const type2 = typeof def === "function" && def.vuex ? "vuex bindings" : "computed";
    computed.push({
      type: type2,
      key,
      value: returnError(() => {
        var _a10;
        return (_a10 = instance == null ? void 0 : instance.proxy) == null ? void 0 : _a10[key];
      }),
      editable: typeof def.set === "function"
    });
  }
  return computed;
}
function processAttrs(instance) {
  return Object.keys(instance.attrs).map((key) => ({
    type: "attrs",
    key,
    value: returnError(() => instance.attrs[key])
  }));
}
function processProvide(instance) {
  return Reflect.ownKeys(instance.provides).map((key) => ({
    type: "provided",
    key: key.toString(),
    value: returnError(() => instance.provides[key])
  }));
}
function processInject(instance, mergedType) {
  if (!(mergedType == null ? void 0 : mergedType.inject))
    return [];
  let keys = [];
  let defaultValue;
  if (Array.isArray(mergedType.inject)) {
    keys = mergedType.inject.map((key) => ({
      key,
      originalKey: key
    }));
  } else {
    keys = Reflect.ownKeys(mergedType.inject).map((key) => {
      const value = mergedType.inject[key];
      let originalKey;
      if (typeof value === "string" || typeof value === "symbol") {
        originalKey = value;
      } else {
        originalKey = value.from;
        defaultValue = value.default;
      }
      return {
        key,
        originalKey
      };
    });
  }
  return keys.map(({ key, originalKey }) => ({
    type: "injected",
    key: originalKey && key !== originalKey ? `${originalKey.toString()} \u279E ${key.toString()}` : key.toString(),
    // eslint-disable-next-line no-prototype-builtins
    value: returnError(() => instance.ctx.hasOwnProperty(key) ? instance.ctx[key] : instance.provides.hasOwnProperty(originalKey) ? instance.provides[originalKey] : defaultValue)
  }));
}
function processRefs(instance) {
  return Object.keys(instance.refs).map((key) => ({
    type: "refs",
    key,
    value: returnError(() => instance.refs[key])
  }));
}
function processEventListeners(instance) {
  var _a10;
  const emitsDefinition = instance.type.emits;
  const declaredEmits = Array.isArray(emitsDefinition) ? emitsDefinition : Object.keys(emitsDefinition != null ? emitsDefinition : {});
  const keys = Object.keys((_a10 = instance.vnode.props) != null ? _a10 : {});
  const result = [];
  for (const key of keys) {
    const [prefix, ...eventNameParts] = key.split(/(?=[A-Z])/);
    if (prefix === "on") {
      const eventName = eventNameParts.join("-").toLowerCase();
      const isDeclared = declaredEmits.includes(eventName);
      result.push({
        type: "event listeners",
        key: eventName,
        value: {
          _custom: {
            displayText: isDeclared ? "\u2705 Declared" : "\u26A0\uFE0F Not declared",
            key: isDeclared ? "\u2705 Declared" : "\u26A0\uFE0F Not declared",
            value: isDeclared ? "\u2705 Declared" : "\u26A0\uFE0F Not declared",
            tooltipText: !isDeclared ? `The event <code>${eventName}</code> is not declared in the <code>emits</code> option. It will leak into the component's attributes (<code>$attrs</code>).` : null
          }
        }
      });
    }
  }
  return result;
}
function processInstanceState(instance) {
  const mergedType = resolveMergedOptions(instance);
  return processProps(instance).concat(
    processState(instance),
    processSetupState(instance),
    processComputed(instance, mergedType),
    processAttrs(instance),
    processProvide(instance),
    processInject(instance, mergedType),
    processRefs(instance),
    processEventListeners(instance)
  );
}

// src/core/component/state/index.ts
function getInstanceState(params) {
  var _a10;
  const instance = getComponentInstance(devtoolsContext.appRecord, params.instanceId);
  const id = getUniqueComponentId(instance);
  const name = getInstanceName(instance);
  const file = (_a10 = instance == null ? void 0 : instance.type) == null ? void 0 : _a10.__file;
  const state = processInstanceState(instance);
  return {
    id,
    name,
    file,
    state,
    instance
  };
}

// src/plugins/component.ts
var INSPECTOR_ID = "components";
function registerComponentDevToolsPlugin(app) {
  setupDevToolsPlugin({
    id: INSPECTOR_ID,
    label: "Components",
    app
  }, (api) => {
    api.addInspector({
      id: INSPECTOR_ID,
      label: "Components",
      treeFilterPlaceholder: "Search components"
    });
    api.on.getComponentBoundingRect((payload) => {
      if (payload.app === app && payload.inspectorId === INSPECTOR_ID) {
        const instance = getComponentInstance(devtoolsContext.appRecord, payload.instanceId);
        if (instance) {
          if (typeof DOMRect === "undefined")
            return;
          payload.rect = getComponentBoundingRect(instance);
          if (payload.rect instanceof DOMRect) {
            payload.rect = {
              top: payload.rect.top,
              left: payload.rect.left,
              right: payload.rect.right,
              bottom: payload.rect.bottom,
              width: payload.rect.width,
              height: payload.rect.height
            };
          }
        }
      }
    });
    api.on.getInspectorTree(async (payload) => {
      if (payload.app === app && payload.inspectorId === INSPECTOR_ID) {
        const instance = getComponentInstance(devtoolsContext.appRecord, payload.instanceId);
        if (instance) {
          const walker = new ComponentWalker({
            filterText: payload.filter,
            // @TODO: should make this configurable?
            maxDepth: 100,
            recursively: false
          });
          payload.rootNodes = await walker.getComponentTree(instance);
        }
      }
    });
    api.on.getInspectorState(async (payload) => {
      var _a10;
      if (payload.app === app && payload.inspectorId === INSPECTOR_ID) {
        const result = getInstanceState({
          instanceId: payload.nodeId
        });
        const componentInstance = result.instance;
        const app2 = (_a10 = result.instance) == null ? void 0 : _a10.appContext.app;
        const _payload = {
          componentInstance,
          app: app2,
          instanceData: result
        };
        apiHooks.callHookWith((callbacks) => {
          callbacks.forEach((cb) => cb(_payload));
        }, "component-state:inspect" /* COMPONENT_STATE_INSPECT */);
        payload.state = result;
      }
    });
    api.on.editInspectorState(async (payload) => {
      if (payload.app === app && payload.inspectorId === INSPECTOR_ID) {
        editState(payload);
        await api.sendInspectorState("components");
      }
    });
    const debounceSendInspectorTree = debounce2(() => {
      api.sendInspectorTree(INSPECTOR_ID);
    }, 120);
    const debounceSendInspectorState = debounce2(() => {
      api.sendInspectorState(INSPECTOR_ID);
    }, 120);
    const componentAddedCleanup = hook.on.componentAdded(async (app2, uid, parentUid, component) => {
      var _a10, _b10, _c;
      if (devtoolsState.highPerfModeEnabled)
        return;
      if ((_c = (_b10 = (_a10 = app2 == null ? void 0 : app2._instance) == null ? void 0 : _a10.type) == null ? void 0 : _b10.devtools) == null ? void 0 : _c.hide)
        return;
      if (!app2 || typeof uid !== "number" && !uid || !component)
        return;
      const id = await getComponentId({
        app: app2,
        uid,
        instance: component
      });
      const appRecord = await getAppRecord(app2);
      if (component) {
        if (component.__VUE_DEVTOOLS_NEXT_UID__ == null)
          component.__VUE_DEVTOOLS_NEXT_UID__ = id;
        if (!(appRecord == null ? void 0 : appRecord.instanceMap.has(id))) {
          appRecord == null ? void 0 : appRecord.instanceMap.set(id, component);
          if (devtoolsAppRecords.active.id === (appRecord == null ? void 0 : appRecord.id))
            devtoolsAppRecords.active.instanceMap = appRecord.instanceMap;
        }
      }
      if (!appRecord)
        return;
      debounceSendInspectorTree();
    });
    const componentUpdatedCleanup = hook.on.componentUpdated(async (app2, uid, parentUid, component) => {
      var _a10, _b10, _c;
      if (devtoolsState.highPerfModeEnabled)
        return;
      if ((_c = (_b10 = (_a10 = app2 == null ? void 0 : app2._instance) == null ? void 0 : _a10.type) == null ? void 0 : _b10.devtools) == null ? void 0 : _c.hide)
        return;
      if (!app2 || typeof uid !== "number" && !uid || !component)
        return;
      const id = await getComponentId({
        app: app2,
        uid,
        instance: component
      });
      const appRecord = await getAppRecord(app2);
      if (component) {
        if (component.__VUE_DEVTOOLS_NEXT_UID__ == null)
          component.__VUE_DEVTOOLS_NEXT_UID__ = id;
        if (!(appRecord == null ? void 0 : appRecord.instanceMap.has(id))) {
          appRecord == null ? void 0 : appRecord.instanceMap.set(id, component);
          if (devtoolsAppRecords.active.id === (appRecord == null ? void 0 : appRecord.id))
            devtoolsAppRecords.active.instanceMap = appRecord.instanceMap;
        }
      }
      if (!appRecord)
        return;
      debounceSendInspectorTree();
      debounceSendInspectorState();
    });
    const componentRemovedCleanup = hook.on.componentRemoved(async (app2, uid, parentUid, component) => {
      var _a10, _b10, _c;
      if (devtoolsState.highPerfModeEnabled)
        return;
      if ((_c = (_b10 = (_a10 = app2 == null ? void 0 : app2._instance) == null ? void 0 : _a10.type) == null ? void 0 : _b10.devtools) == null ? void 0 : _c.hide)
        return;
      if (!app2 || typeof uid !== "number" && !uid || !component)
        return;
      const appRecord = await getAppRecord(app2);
      if (!appRecord)
        return;
      const id = await getComponentId({
        app: app2,
        uid,
        instance: component
      });
      appRecord == null ? void 0 : appRecord.instanceMap.delete(id);
      if (devtoolsAppRecords.active.id === (appRecord == null ? void 0 : appRecord.id))
        devtoolsAppRecords.active.instanceMap = appRecord.instanceMap;
      debounceSendInspectorTree();
    });
    devtoolsContext.componentPluginHookBuffer = [
      componentAddedCleanup,
      componentUpdatedCleanup,
      componentRemovedCleanup
    ];
  });
}

// src/core/app-record/index.ts
function getAppRecordName(app, fallbackName) {
  var _a10;
  return ((_a10 = app == null ? void 0 : app._component) == null ? void 0 : _a10.name) || `App ${fallbackName}`;
}
function getAppRootInstance(app) {
  var _a10, _b10, _c, _d;
  if (app._instance)
    return app._instance;
  else if ((_b10 = (_a10 = app._container) == null ? void 0 : _a10._vnode) == null ? void 0 : _b10.component)
    return (_d = (_c = app._container) == null ? void 0 : _c._vnode) == null ? void 0 : _d.component;
}
function getAppRecordId(app, defaultId) {
  if (app.__VUE_DEVTOOLS_NEXT_APP_RECORD_ID__ != null)
    return app.__VUE_DEVTOOLS_NEXT_APP_RECORD_ID__;
  let id = defaultId != null ? defaultId : (appRecordInfo.id++).toString();
  if (defaultId && appRecordInfo.appIds.has(id)) {
    let count = 1;
    while (appRecordInfo.appIds.has(`${defaultId}_${count}`))
      count++;
    id = `${defaultId}_${count}`;
  }
  appRecordInfo.appIds.add(id);
  app.__VUE_DEVTOOLS_NEXT_APP_RECORD_ID__ = id;
  return id;
}
function createAppRecord(app) {
  const rootInstance = getAppRootInstance(app);
  if (rootInstance) {
    appRecordInfo.id++;
    const name = getAppRecordName(app, appRecordInfo.id.toString());
    const id = getAppRecordId(app, (0, import_speakingurl.default)(name));
    const record = {
      id,
      name,
      instanceMap: /* @__PURE__ */ new Map(),
      rootInstance
    };
    app.__VUE_DEVTOOLS_NEXT_APP_RECORD__ = record;
    const rootId = `${record.id}:root`;
    record.instanceMap.set(rootId, record.rootInstance);
    record.rootInstance.__VUE_DEVTOOLS_NEXT_UID__ = rootId;
    return record;
  } else {
    return {};
  }
}
async function setActiveAppRecord(appRecord) {
  await registerComponentDevToolsPlugin(appRecord == null ? void 0 : appRecord.app);
  devtoolsAppRecords.active = appRecord;
  devtoolsAppRecords.activeId = `${appRecord.id}`;
  registerPlugin(appRecord.app, appRecord.api);
}
async function toggleActiveAppRecord(id) {
  devtoolsContext.componentPluginHookBuffer.forEach((cleanup) => cleanup());
  devtoolsContext.api.clear();
  devtoolsContext.clear();
  const appRecord = devtoolsAppRecords.value.find((record) => record.id === id);
  if (appRecord) {
    devtoolsState.pluginBuffer = devtoolsState.pluginBuffer.filter(([plugin]) => plugin.id !== "components");
    const api = new DevToolsPluginApi();
    appRecord.api = api;
    setActiveAppRecord(appRecord);
  }
}

// src/core/component-highlighter/index.ts
init_esm_shims();
var CONTAINER_ELEMENT_ID = "__vue-devtools-component-inspector__";
var CARD_ELEMENT_ID = "__vue-devtools-component-inspector__card__";
var COMPONENT_NAME_ELEMENT_ID = "__vue-devtools-component-inspector__name__";
var INDICATOR_ELEMENT_ID = "__vue-devtools-component-inspector__indicator__";
var containerStyles = {
  display: "block",
  zIndex: 2147483640,
  position: "fixed",
  backgroundColor: "#42b88325",
  border: "1px solid #42b88350",
  borderRadius: "5px",
  transition: "all 0.1s ease-in",
  pointerEvents: "none"
};
var cardStyles = {
  fontFamily: "Arial, Helvetica, sans-serif",
  padding: "5px 8px",
  borderRadius: "4px",
  textAlign: "left",
  position: "absolute",
  left: 0,
  color: "#e9e9e9",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "24px",
  backgroundColor: "#42b883",
  boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)"
};
var indicatorStyles = {
  display: "inline-block",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: "12px",
  opacity: 0.7
};
function getContainerElement() {
  return document.getElementById(CONTAINER_ELEMENT_ID);
}
function getCardElement() {
  return document.getElementById(CARD_ELEMENT_ID);
}
function getIndicatorElement() {
  return document.getElementById(INDICATOR_ELEMENT_ID);
}
function getNameElement() {
  return document.getElementById(COMPONENT_NAME_ELEMENT_ID);
}
function getStyles(bounds) {
  return {
    left: `${Math.round(bounds.left * 100) / 100}px`,
    top: `${Math.round(bounds.top * 100) / 100}px`,
    width: `${Math.round(bounds.width * 100) / 100}px`,
    height: `${Math.round(bounds.height * 100) / 100}px`
  };
}
function create(options) {
  var _a10;
  const containerEl = document.createElement("div");
  containerEl.id = (_a10 = options.elementId) != null ? _a10 : CONTAINER_ELEMENT_ID;
  Object.assign(containerEl.style, {
    ...containerStyles,
    ...getStyles(options.bounds),
    ...options.style
  });
  const cardEl = document.createElement("span");
  cardEl.id = CARD_ELEMENT_ID;
  Object.assign(cardEl.style, {
    ...cardStyles,
    top: options.bounds.top < 35 ? 0 : "-35px"
  });
  const nameEl = document.createElement("span");
  nameEl.id = COMPONENT_NAME_ELEMENT_ID;
  nameEl.innerHTML = `&lt;${options.name}&gt;&nbsp;&nbsp;`;
  const indicatorEl = document.createElement("i");
  indicatorEl.id = INDICATOR_ELEMENT_ID;
  indicatorEl.innerHTML = `${Math.round(options.bounds.width * 100) / 100} x ${Math.round(options.bounds.height * 100) / 100}`;
  Object.assign(indicatorEl.style, indicatorStyles);
  cardEl.appendChild(nameEl);
  cardEl.appendChild(indicatorEl);
  containerEl.appendChild(cardEl);
  document.body.appendChild(containerEl);
  return containerEl;
}
function update(options) {
  const containerEl = getContainerElement();
  const cardEl = getCardElement();
  const nameEl = getNameElement();
  const indicatorEl = getIndicatorElement();
  if (containerEl) {
    Object.assign(containerEl.style, {
      ...containerStyles,
      ...getStyles(options.bounds)
    });
    Object.assign(cardEl.style, {
      top: options.bounds.top < 35 ? 0 : "-35px"
    });
    nameEl.innerHTML = `&lt;${options.name}&gt;&nbsp;&nbsp;`;
    indicatorEl.innerHTML = `${Math.round(options.bounds.width * 100) / 100} x ${Math.round(options.bounds.height * 100) / 100}`;
  }
}
function toggleComponentHighLighter(options) {
  if (options.visible) {
    const instance = getComponentInstance(devtoolsContext.appRecord, options.id);
    if (instance && (options.bounds.width || options.bounds.height)) {
      const name = getInstanceName(instance);
      const el = getContainerElement();
      el ? update({ ...options, name }) : create({ ...options, name });
    }
  } else {
    const el = getContainerElement();
    if (el)
      el.style.display = "none";
  }
}
function highlight(instance) {
  const bounds = getComponentBoundingRect(instance);
  const name = getInstanceName(instance);
  const container = getContainerElement();
  container ? update({ bounds, name }) : create({ bounds, name });
}
function unhighlight() {
  const el = getContainerElement();
  if (el)
    el.style.display = "none";
}
var inspectInstance = null;
function inspectFn(e) {
  const target10 = e.target;
  if (target10) {
    const instance = target10.__vueParentComponent;
    if (instance) {
      inspectInstance = instance;
      const el = instance.vnode.el;
      if (el) {
        const bounds = getComponentBoundingRect(instance);
        const name = getInstanceName(instance);
        const container = getContainerElement();
        container ? update({ bounds, name }) : create({ bounds, name });
      }
    }
  }
}
function selectComponentFn(e, cb) {
  var _a10;
  e.preventDefault();
  e.stopPropagation();
  if (inspectInstance) {
    const app = (_a10 = devtoolsContext.appRecord) == null ? void 0 : _a10.app;
    getComponentId({
      app,
      uid: app.uid,
      instance: inspectInstance
    }).then((id) => {
      cb(id);
    });
  }
}
var inspectComponentHighLighterSelectFn = null;
function cancelInspectComponentHighLighter() {
  window.removeEventListener("mouseover", inspectFn);
  window.removeEventListener("click", inspectComponentHighLighterSelectFn, true);
  inspectComponentHighLighterSelectFn = null;
}
function inspectComponentHighLighter() {
  window.addEventListener("mouseover", inspectFn);
  return new Promise((resolve) => {
    function onSelect(e) {
      e.preventDefault();
      e.stopPropagation();
      selectComponentFn(e, (id) => {
        window.removeEventListener("click", onSelect, true);
        inspectComponentHighLighterSelectFn = null;
        window.removeEventListener("mouseover", inspectFn);
        const el = getContainerElement();
        if (el)
          el.style.display = "none";
        resolve(JSON.stringify({ id }));
      });
    }
    inspectComponentHighLighterSelectFn = onSelect;
    window.addEventListener("click", onSelect, true);
  });
}
function scrollToComponent(options) {
  const instance = getComponentInstance(devtoolsContext.appRecord, options.id);
  if (instance) {
    const [el] = getRootElementsFromComponentInstance(instance);
    if (typeof el.scrollIntoView === "function") {
      el.scrollIntoView({
        behavior: "smooth"
      });
    } else {
      const bounds = getComponentBoundingRect(instance);
      const scrollTarget = document.createElement("div");
      const styles = {
        ...getStyles(bounds),
        position: "absolute"
      };
      Object.assign(scrollTarget.style, styles);
      document.body.appendChild(scrollTarget);
      scrollTarget.scrollIntoView({
        behavior: "smooth"
      });
      setTimeout(() => {
        document.body.removeChild(scrollTarget);
      }, 2e3);
    }
    setTimeout(() => {
      const bounds = getComponentBoundingRect(instance);
      if (bounds.width || bounds.height) {
        const name = getInstanceName(instance);
        const el2 = getContainerElement();
        el2 ? update({ ...options, name, bounds }) : create({ ...options, name, bounds });
        setTimeout(() => {
          if (el2)
            el2.style.display = "none";
        }, 1500);
      }
    }, 1200);
  }
}

// src/shared/index.ts
init_esm_shims();

// src/shared/util.ts
init_esm_shims();

// src/core/component/state/replacer.ts
init_esm_shims();

// src/core/component/state/custom.ts
init_esm_shims();
function getFunctionDetails(func) {
  let string = "";
  let matches = null;
  try {
    string = Function.prototype.toString.call(func);
    matches = String.prototype.match.call(string, /\([\s\S]*?\)/);
  } catch (e) {
  }
  const match = matches && matches[0];
  const args = typeof match === "string" ? match : "(?)";
  const name = typeof func.name === "string" ? func.name : "";
  return {
    _custom: {
      type: "function",
      displayText: `<span style="opacity:.5;margin-right:5px;">function</span> <span style="white-space:nowrap;">${escape(name)}${args}</span>`,
      tooltipText: string.trim() ? `<pre>${string}</pre>` : null
    }
  };
}
function getBigIntDetails(val) {
  const stringifiedBigInt = BigInt.prototype.toString.call(val);
  return {
    _custom: {
      type: "bigint",
      displayText: `BigInt(${stringifiedBigInt})`,
      value: stringifiedBigInt
    }
  };
}
function getDateDetails(val) {
  const date = new Date(val.getTime());
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return {
    _custom: {
      type: "date",
      displayText: Date.prototype.toString.call(val),
      value: date.toISOString().slice(0, -1)
    }
  };
}
function getMapDetails(val) {
  const list = Object.fromEntries(val);
  return {
    _custom: {
      type: "map",
      displayText: "Map",
      value: list,
      readOnly: true,
      fields: {
        abstract: true
      }
    }
  };
}
function getSetDetails(val) {
  const list = Array.from(val);
  return {
    _custom: {
      type: "set",
      displayText: `Set[${list.length}]`,
      value: list,
      readOnly: true
    }
  };
}
function getCaughtGetters(store) {
  const getters = {};
  const origGetters = store.getters || {};
  const keys = Object.keys(origGetters);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    Object.defineProperty(getters, key, {
      enumerable: true,
      get: () => {
        try {
          return origGetters[key];
        } catch (e) {
          return e;
        }
      }
    });
  }
  return getters;
}
function reduceStateList(list) {
  if (!list.length)
    return void 0;
  return list.reduce((map, item) => {
    const key = item.type || "data";
    const obj = map[key] = map[key] || {};
    obj[item.key] = item.value;
    return map;
  }, {});
}
function namedNodeMapToObject(map) {
  const result = {};
  const l = map.length;
  for (let i = 0; i < l; i++) {
    const node = map.item(i);
    result[node.name] = node.value;
  }
  return result;
}
function getStoreDetails(store) {
  return {
    _custom: {
      type: "store",
      displayText: "Store",
      value: {
        state: store.state,
        getters: getCaughtGetters(store)
      },
      fields: {
        abstract: true
      }
    }
  };
}
function getRouterDetails(router) {
  return {
    _custom: {
      type: "router",
      displayText: "VueRouter",
      value: {
        options: router.options,
        currentRoute: router.currentRoute
      },
      fields: {
        abstract: true
      }
    }
  };
}
function getInstanceDetails(instance) {
  if (instance._)
    instance = instance._;
  const state = processInstanceState(instance);
  return {
    _custom: {
      type: "component",
      id: instance.__VUE_DEVTOOLS_NEXT_UID__,
      displayText: getInstanceName(instance),
      tooltipText: "Component instance",
      value: reduceStateList(state),
      fields: {
        abstract: true
      }
    }
  };
}
function getComponentDefinitionDetails(definition) {
  let display = getComponentName(definition);
  if (display) {
    if (definition.name && definition.__file)
      display += ` <span>(${definition.__file})</span>`;
  } else {
    display = "<i>Unknown Component</i>";
  }
  return {
    _custom: {
      type: "component-definition",
      displayText: display,
      tooltipText: "Component definition",
      ...definition.__file ? {
        file: definition.__file
      } : {}
    }
  };
}
function getHTMLElementDetails(value) {
  try {
    return {
      _custom: {
        type: "HTMLElement",
        displayText: `<span class="opacity-30">&lt;</span><span class="text-blue-500">${value.tagName.toLowerCase()}</span><span class="opacity-30">&gt;</span>`,
        value: namedNodeMapToObject(value.attributes)
      }
    };
  } catch (e) {
    return {
      _custom: {
        type: "HTMLElement",
        displayText: `<span class="text-blue-500">${String(value)}</span>`
      }
    };
  }
}
function getObjectDetails(object) {
  var _a10, _b10, _c, _d;
  const info = getSetupStateType(object);
  const isState = info.ref || info.computed || info.reactive;
  if (isState) {
    const stateTypeName = info.computed ? "Computed" : info.ref ? "Ref" : info.reactive ? "Reactive" : null;
    const value = toRaw2(info.reactive ? object : object._value);
    const raw = ((_b10 = (_a10 = object.effect) == null ? void 0 : _a10.raw) == null ? void 0 : _b10.toString()) || ((_d = (_c = object.effect) == null ? void 0 : _c.fn) == null ? void 0 : _d.toString());
    return {
      _custom: {
        type: stateTypeName == null ? void 0 : stateTypeName.toLowerCase(),
        stateTypeName,
        value,
        ...raw ? { tooltipText: `<span class="font-mono">${raw}</span>` } : {}
      }
    };
  }
  if (typeof object.__asyncLoader === "function") {
    return {
      _custom: {
        type: "component-definition",
        display: "Async component definition"
      }
    };
  }
}

// src/core/component/state/replacer.ts
function stringifyReplacer(key) {
  var _a10;
  if (key === "compilerOptions")
    return;
  const val = this[key];
  const type = typeof val;
  if (Array.isArray(val)) {
    const l = val.length;
    if (l > MAX_ARRAY_SIZE) {
      return {
        _isArray: true,
        length: l,
        items: val.slice(0, MAX_ARRAY_SIZE)
      };
    }
    return val;
  } else if (typeof val === "string") {
    if (val.length > MAX_STRING_SIZE)
      return `${val.substring(0, MAX_STRING_SIZE)}... (${val.length} total length)`;
    else
      return val;
  } else if (type === "undefined") {
    return UNDEFINED;
  } else if (val === Number.POSITIVE_INFINITY) {
    return INFINITY;
  } else if (val === Number.NEGATIVE_INFINITY) {
    return NEGATIVE_INFINITY;
  } else if (typeof val === "function") {
    return getFunctionDetails(val);
  } else if (type === "symbol") {
    return `[native Symbol ${Symbol.prototype.toString.call(val)}]`;
  } else if (typeof val === "bigint") {
    return getBigIntDetails(val);
  } else if (val !== null && typeof val === "object") {
    const proto = Object.prototype.toString.call(val);
    if (proto === "[object Map]") {
      return getMapDetails(val);
    } else if (proto === "[object Set]") {
      return getSetDetails(val);
    } else if (proto === "[object RegExp]") {
      return `[native RegExp ${RegExp.prototype.toString.call(val)}]`;
    } else if (proto === "[object Date]") {
      return getDateDetails(val);
    } else if (proto === "[object Error]") {
      return `[native Error ${val.message}<>${val.stack}]`;
    } else if (val.state && val._vm) {
      return getStoreDetails(val);
    } else if (val.constructor && val.constructor.name === "VueRouter") {
      return getRouterDetails(val);
    } else if (isVueInstance(val)) {
      return getInstanceDetails(val);
    } else if (typeof val.render === "function") {
      return getComponentDefinitionDetails(val);
    } else if (val.constructor && val.constructor.name === "VNode") {
      return `[native VNode <${val.tag}>]`;
    } else if (typeof HTMLElement !== "undefined" && val instanceof HTMLElement) {
      return getHTMLElementDetails(val);
    } else if (((_a10 = val.constructor) == null ? void 0 : _a10.name) === "Store" && val._wrappedGetters) {
      return "[object Store]";
    } else if (val.currentRoute) {
      return "[object Router]";
    }
    const customDetails = getObjectDetails(val);
    if (customDetails != null)
      return customDetails;
  } else if (Number.isNaN(val)) {
    return NAN;
  }
  return sanitize(val);
}

// src/core/component/state/reviver.ts
init_esm_shims();
import { target as target3 } from "@vue/devtools-shared";
function reviveSet(val) {
  const result = /* @__PURE__ */ new Set();
  const list = val._custom.value;
  for (let i = 0; i < list.length; i++) {
    const value = list[i];
    result.add(revive(value));
  }
  return result;
}
function reviveMap(val) {
  const result = /* @__PURE__ */ new Map();
  const list = val._custom.value;
  for (let i = 0; i < list.length; i++) {
    const { key, value } = list[i];
    result.set(key, revive(value));
  }
  return result;
}
function revive(val) {
  var _a10;
  if (val === UNDEFINED) {
    return void 0;
  } else if (val === INFINITY) {
    return Number.POSITIVE_INFINITY;
  } else if (val === NEGATIVE_INFINITY) {
    return Number.NEGATIVE_INFINITY;
  } else if (val === NAN) {
    return Number.NaN;
  } else if (val && val._custom) {
    const { _custom: custom } = val;
    if (custom.type === "component")
      return (_a10 = devtoolsContext.appRecord) == null ? void 0 : _a10.instanceMap.get(custom.id);
    else if (custom.type === "map")
      return reviveMap(val);
    else if (custom.type === "set")
      return reviveSet(val);
    else if (custom.type === "bigint")
      return BigInt(custom.value);
    else
      return revive(custom.value);
  } else if (symbolRE.test(val)) {
    const [, string] = symbolRE.exec(val);
    return Symbol.for(string);
  } else if (specialTypeRE.test(val)) {
    const [, type, string, , details] = specialTypeRE.exec(val);
    const result = new target3[type](string);
    if (type === "Error" && details)
      result.stack = details;
    return result;
  } else {
    return val;
  }
}
function reviver(key, value) {
  return revive(value);
}

// src/shared/transfer.ts
init_esm_shims();
var MAX_SERIALIZED_SIZE = 512 * 1024;
function encode(data, replacer, list, seen) {
  let stored, key, value, i, l;
  const seenIndex = seen.get(data);
  if (seenIndex != null)
    return seenIndex;
  const index = list.length;
  const proto = Object.prototype.toString.call(data);
  if (proto === "[object Object]") {
    stored = {};
    seen.set(data, index);
    list.push(stored);
    const keys = Object.keys(data);
    for (i = 0, l = keys.length; i < l; i++) {
      key = keys[i];
      try {
        if (key === "compilerOptions")
          return;
        value = data[key];
        if (replacer)
          value = replacer.call(data, key, value);
      } catch (e) {
        value = e;
      }
      stored[key] = encode(value, replacer, list, seen);
    }
  } else if (proto === "[object Array]") {
    stored = [];
    seen.set(data, index);
    list.push(stored);
    for (i = 0, l = data.length; i < l; i++) {
      try {
        value = data[i];
        if (replacer)
          value = replacer.call(data, i, value);
      } catch (e) {
        value = e;
      }
      stored[i] = encode(value, replacer, list, seen);
    }
  } else {
    list.push(data);
  }
  return index;
}
function decode(list, reviver2 = null) {
  let i = list.length;
  let j, k, data, key, value, proto;
  while (i--) {
    data = list[i];
    proto = Object.prototype.toString.call(data);
    if (proto === "[object Object]") {
      const keys = Object.keys(data);
      for (j = 0, k = keys.length; j < k; j++) {
        key = keys[j];
        value = list[data[key]];
        if (reviver2)
          value = reviver2.call(data, key, value);
        data[key] = value;
      }
    } else if (proto === "[object Array]") {
      for (j = 0, k = data.length; j < k; j++) {
        value = list[data[j]];
        if (reviver2)
          value = reviver2.call(data, j, value);
        data[j] = value;
      }
    }
  }
}
function stringifyCircularAutoChunks(data, replacer = null, space = null) {
  let result;
  try {
    result = arguments.length === 1 ? JSON.stringify(data) : JSON.stringify(data, replacer, space);
  } catch (e) {
    result = stringifyStrictCircularAutoChunks(data, replacer, space);
  }
  if (result.length > MAX_SERIALIZED_SIZE) {
    const chunkCount = Math.ceil(result.length / MAX_SERIALIZED_SIZE);
    const chunks = [];
    for (let i = 0; i < chunkCount; i++)
      chunks.push(result.slice(i * MAX_SERIALIZED_SIZE, (i + 1) * MAX_SERIALIZED_SIZE));
    return chunks;
  }
  return result;
}
function stringifyStrictCircularAutoChunks(data, replacer = null, space = null) {
  const list = [];
  encode(data, replacer, list, /* @__PURE__ */ new Map());
  return space ? ` ${JSON.stringify(list, null, space)}` : ` ${JSON.stringify(list)}`;
}
function parseCircularAutoChunks(data, reviver2 = null) {
  if (Array.isArray(data))
    data = data.join("");
  const hasCircular = /^\s/.test(data);
  if (!hasCircular) {
    return arguments.length === 1 ? JSON.parse(data) : JSON.parse(data, reviver2);
  } else {
    const list = JSON.parse(data);
    decode(list, reviver2);
    return list[0];
  }
}

// src/core/component/state/format.ts
init_esm_shims();
function getInspectorStateValueType(value, raw = true) {
  const type = typeof value;
  if (value == null || value === UNDEFINED || value === "undefined") {
    return "null";
  } else if (type === "boolean" || type === "number" || value === INFINITY || value === NEGATIVE_INFINITY || value === NAN) {
    return "literal";
  } else if (value == null ? void 0 : value._custom) {
    if (raw || value._custom.display != null || value._custom.displayText != null)
      return "custom";
    else
      return getInspectorStateValueType(value._custom.value);
  } else if (typeof value === "string") {
    const typeMatch = specialTypeRE.exec(value);
    if (typeMatch) {
      const [, type2] = typeMatch;
      return `native ${type2}`;
    } else {
      return "string";
    }
  } else if (Array.isArray(value) || (value == null ? void 0 : value._isArray)) {
    return "array";
  } else if (isPlainObject(value)) {
    return "plain-object";
  } else {
    return "unknown";
  }
}
function formatInspectorStateValue(value, quotes = false) {
  var _a10, _b10;
  let result;
  const type = getInspectorStateValueType(value, false);
  if (type !== "custom" && (value == null ? void 0 : value._custom))
    value = value._custom.value;
  if (result = internalStateTokenToString(value)) {
    return result;
  } else if (type === "custom") {
    const nestedName = ((_a10 = value._custom.value) == null ? void 0 : _a10._custom) && formatInspectorStateValue(value._custom.value);
    return nestedName || value._custom.displayText || value._custom.display;
  } else if (type === "array") {
    return `Array[${value.length}]`;
  } else if (type === "plain-object") {
    return `Object${Object.keys(value).length ? "" : " (empty)"}`;
  } else if (type == null ? void 0 : type.includes("native")) {
    return escape((_b10 = specialTypeRE.exec(value)) == null ? void 0 : _b10[2]);
  } else if (typeof value === "string") {
    const typeMatch = value.match(rawTypeRE);
    if (typeMatch)
      value = escape(typeMatch[1]);
    else if (quotes)
      value = `<span>"</span>${escape(value)}<span>"</span>`;
    else
      value = escape(value);
    value = value.replace(/ /g, "&nbsp;").replace(/\n/g, "<span>\\n</span>");
  }
  return value;
}
function getRaw(value) {
  var _a10, _b10, _c;
  let customType;
  const isCustom = getInspectorStateValueType(value) === "custom";
  let inherit = {};
  if (isCustom) {
    const data = value;
    const customValue = (_a10 = data._custom) == null ? void 0 : _a10.value;
    const currentCustomType = (_b10 = data._custom) == null ? void 0 : _b10.type;
    const nestedCustom = typeof customValue === "object" && customValue !== null && "_custom" in customValue ? getRaw(customValue) : { inherit: void 0, value: void 0, customType: void 0 };
    inherit = nestedCustom.inherit || ((_c = data._custom) == null ? void 0 : _c.fields) || {};
    value = nestedCustom.value || customValue;
    customType = nestedCustom.customType || currentCustomType;
  }
  if (value && value._isArray)
    value = value.items;
  return { value, inherit, customType };
}
function toEdit(value, customType) {
  if (customType === "bigint")
    return value;
  if (customType === "date")
    return value;
  return replaceTokenToString(JSON.stringify(value));
}
function toSubmit(value, customType) {
  if (customType === "bigint")
    return BigInt(value);
  if (customType === "date")
    return new Date(value);
  return JSON.parse(replaceStringToToken(value), reviver);
}

// src/shared/util.ts
function stringify(data) {
  return stringifyCircularAutoChunks(data, stringifyReplacer);
}
function parse(data, revive2 = false) {
  if (data == void 0)
    return {};
  return revive2 ? parseCircularAutoChunks(data, reviver) : parseCircularAutoChunks(data);
}

// src/shared/time.ts
init_esm_shims();
function now() {
  return Date.now();
}

// src/shared/env.ts
init_esm_shims();

// src/core/custom-tab/index.ts
init_esm_shims();
function addCustomTab(tab) {
  if (devtoolsState.tabs.some((t) => t.name === tab.name))
    return;
  devtoolsState.tabs.push(tab);
}

// src/core/custom-command/index.ts
init_esm_shims();
function addCustomCommand(action) {
  if (devtoolsState.commands.some((t) => t.id === action.id))
    return;
  devtoolsState.commands.push(action);
}
function removeCustomCommand(actionId) {
  const index = devtoolsState.commands.findIndex((t) => t.id === actionId);
  if (index === -1)
    return;
  devtoolsState.commands.splice(index, 1);
}

// src/core/component-inspector/index.ts
init_esm_shims();
import { target as target4 } from "@vue/devtools-shared";
var _a3, _b3;
(_b3 = (_a3 = target4).__VUE_DEVTOOLS_COMPONENT_INSPECTOR_ENABLED__) != null ? _b3 : _a3.__VUE_DEVTOOLS_COMPONENT_INSPECTOR_ENABLED__ = true;
function toggleComponentInspectorEnabled(enabled) {
  target4.__VUE_DEVTOOLS_COMPONENT_INSPECTOR_ENABLED__ = enabled;
}
function waitForInspectorInit(cb) {
  let total = 0;
  const timer = setInterval(() => {
    if (target4.__VUE_INSPECTOR__) {
      clearInterval(timer);
      total += 30;
      cb();
    }
    if (total >= /* 5s */
    5e3)
      clearInterval(timer);
  }, 30);
}
function setupInspector() {
  const inspector = target4.__VUE_INSPECTOR__;
  const _openInEditor = inspector.openInEditor;
  inspector.openInEditor = async (...params) => {
    inspector.disable();
    _openInEditor(...params);
  };
}
function getComponentInspector() {
  return new Promise((resolve) => {
    function setup() {
      setupInspector();
      resolve(target4.__VUE_INSPECTOR__);
    }
    if (!target4.__VUE_INSPECTOR__) {
      waitForInspectorInit(() => {
        setup();
      });
    } else {
      setup();
    }
  });
}

// src/core/open-in-editor/index.ts
init_esm_shims();
import { target as target5 } from "@vue/devtools-shared";
function setOpenInEditorBaseUrl(url) {
  target5.__VUE_DEVTOOLS_OPEN_IN_EDITOR_BASE_URL__ = url;
}
function openInEditor(options = {}) {
  var _a10;
  const { file, baseUrl = window.location.origin, line = 0, column = 0 } = options;
  if (file) {
    if (devtoolsState.vitePluginDetected) {
      const _baseUrl = (_a10 = target5.__VUE_DEVTOOLS_OPEN_IN_EDITOR_BASE_URL__) != null ? _a10 : baseUrl;
      target5.__VUE_INSPECTOR__.openInEditor(_baseUrl, file, line, column);
    } else {
    }
  }
}

// src/api/on.ts
init_esm_shims();
var on2 = {
  // #region compatible with old devtools
  addTimelineEvent(fn) {
    registerInstanceHook("timeline:add-event" /* ADD_TIMELINE_EVENT */, fn);
  },
  inspectComponent(fn) {
    registerInstanceHook("component-state:inspect" /* COMPONENT_STATE_INSPECT */, fn);
  },
  visitComponentTree(fn) {
    registerInstanceHook("component-tree:visit" /* VISIT_COMPONENT_TREE */, fn);
  },
  setPluginSettings() {
  },
  getInspectorTree(fn) {
    registerInstanceHook("inspector-tree:get" /* GET_INSPECTOR_TREE */, fn);
  },
  getInspectorState(fn) {
    registerInstanceHook("inspector-state:get" /* GET_INSPECTOR_STATE */, fn);
  },
  sendInspectorTree(fn) {
    registerInstanceHook("inspector-tree:send" /* SEND_INSPECTOR_TREE */, fn);
  },
  sendInspectorState(fn) {
    registerInstanceHook("inspector-state:send" /* SEND_INSPECTOR_STATE */, fn);
  },
  editInspectorState(fn) {
    registerInstanceHook("inspector-state:edit" /* EDIT_INSPECTOR_STATE */, fn);
  },
  editComponentState() {
  },
  componentUpdated(fn) {
    registerInstanceHook("component:updated" /* COMPONENT_UPDATED */, fn);
  },
  // #endregion compatible with old devtools
  // router
  routerInfoUpdated(fn) {
    registerInstanceHook("router-info:updated" /* ROUTER_INFO_UPDATED */, fn);
  },
  // component highlighter
  getComponentBoundingRect(fn) {
    registerInstanceHook("component-bounding-rect:get" /* GET_COMPONENT_BOUNDING_RECT */, fn);
  },
  // custom tabs
  customTabsUpdated(fn) {
    registerInstanceHook("custom-tabs:updated" /* CUSTOM_TABS_UPDATED */, fn);
  },
  // custom commands
  customCommandsUpdated(fn) {
    registerInstanceHook("custom-commands:updated" /* CUSTOM_COMMANDS_UPDATED */, fn);
  },
  devtoolsStateUpdated(fn) {
    apiHooks.hook("devtools:state-updated" /* DEVTOOLS_STATE_UPDATED */, fn);
  }
};

// src/api/off.ts
init_esm_shims();
function remove() {
  instanceHooks.forEach((unregister) => unregister());
}

// src/api/api.ts
var DevToolsPluginApi = class {
  constructor() {
    this.clear = remove;
    this.on = on2;
  }
  // #region compatible with old devtools
  // timeline layer
  addTimelineLayer(payload) {
    addTimelineLayer(payload);
  }
  // timeline event
  addTimelineEvent(...params) {
    apiHooks.callHook("timeline:add-event" /* ADD_TIMELINE_EVENT */, ...params);
  }
  // add inspector
  addInspector(payload) {
    addInspector({
      id: payload.id,
      nodeId: "",
      filter: "",
      treeFilterPlaceholder: payload.treeFilterPlaceholder || "",
      actions: payload.actions || [],
      nodeActions: payload.nodeActions || []
    });
    updatePluginDetectives();
  }
  // get inspector node action
  getInspectorNodeActions(inspectorId) {
    var _a10;
    const inspector = getInspector(inspectorId);
    return ((_a10 = inspector == null ? void 0 : inspector.nodeActions) == null ? void 0 : _a10.map(({ icon, tooltip }) => ({ icon, tooltip }))) || [];
  }
  // call inspector node action
  callInspectorNodeAction(inspectorId, actionIndex, nodeId) {
    var _a10;
    const inspector = getInspector(inspectorId);
    if (inspector && inspector.nodeActions) {
      const item = inspector.nodeActions[actionIndex];
      (_a10 = item.action) == null ? void 0 : _a10.call(item, nodeId);
    }
  }
  // get inspector action
  getInspectorActions(inspectorId) {
    var _a10;
    const inspector = getInspector(inspectorId);
    return ((_a10 = inspector == null ? void 0 : inspector.actions) == null ? void 0 : _a10.map(({ icon, tooltip }) => ({ icon, tooltip }))) || [];
  }
  // call inspector action
  callInspectorAction(inspectorId, actionIndex, nodeId) {
    var _a10;
    const inspector = getInspector(inspectorId);
    if (inspector && inspector.actions) {
      const item = inspector.actions[actionIndex];
      (_a10 = item.action) == null ? void 0 : _a10.call(item, nodeId);
    }
  }
  highlightElement(instance) {
    highlight(instance);
  }
  unhighlightElement() {
    unhighlight();
  }
  // inspector
  async getInspectorTree(payload = {}) {
    var _a10;
    const { inspectorId, filter = "", instanceId = "" } = payload;
    const _payload = {
      app: (_a10 = devtoolsContext.appRecord) == null ? void 0 : _a10.app,
      inspectorId,
      instanceId,
      filter,
      rootNodes: []
    };
    updateInspector(inspectorId, {
      filter
    });
    await new Promise((resolve) => {
      apiHooks.callHookWith(async (callbacks) => {
        await Promise.all(callbacks.map((cb) => cb(_payload)));
        resolve();
      }, "inspector-tree:get" /* GET_INSPECTOR_TREE */);
    });
    return _payload.rootNodes;
  }
  getInspectorState(payload = {}) {
    var _a10;
    const { inspectorId, nodeId } = payload;
    const _payload = {
      app: (_a10 = devtoolsContext.appRecord) == null ? void 0 : _a10.app,
      inspectorId,
      nodeId
    };
    const ctx = {
      currentTab: `custom-inspector:${inspectorId}`
    };
    updateInspector(inspectorId, {
      nodeId
    });
    apiHooks.callHookWith((callbacks) => {
      callbacks.forEach((cb) => cb(_payload, ctx));
    }, "inspector-state:get" /* GET_INSPECTOR_STATE */);
    const state = _payload.state;
    state == null ? true : delete state.instance;
    return state;
  }
  async editInspectorState(payload) {
    var _a10;
    const stateEditor2 = new StateEditor();
    apiHooks.callHook("inspector-state:edit" /* EDIT_INSPECTOR_STATE */, {
      ...payload,
      app: (_a10 = devtoolsContext.appRecord) == null ? void 0 : _a10.app,
      set: (obj, path = payload.path, value = payload.state.value, cb) => {
        stateEditor2.set(obj, path, value, cb || stateEditor2.createDefaultSetCallback(payload.state));
      }
    });
  }
  async sendInspectorTree(inspectorId) {
    const inspector = getInspector(inspectorId);
    if (inspector) {
      const res = await this.getInspectorTree({
        inspectorId
      });
      apiHooks.callHook("inspector-tree:send" /* SEND_INSPECTOR_TREE */, {
        inspectorId,
        data: res
      });
    }
  }
  async sendInspectorState(inspectorId) {
    const inspector = getInspector(inspectorId);
    if (inspector && inspector.nodeId) {
      const res = await this.getInspectorState({
        inspectorId,
        nodeId: inspector.nodeId
      });
      apiHooks.callHook("inspector-state:send" /* SEND_INSPECTOR_STATE */, { ...res, inspectorId });
    }
  }
  async getComponentInstances(app) {
    const appRecord = app.__VUE_DEVTOOLS_NEXT_APP_RECORD__;
    const appId = appRecord.id.toString();
    const instances = [...appRecord.instanceMap].filter(([key]) => key.split(":")[0] === appId).map(([, instance]) => instance);
    return instances;
  }
  visitComponentTree(...params) {
    apiHooks.callHook("component-tree:visit" /* VISIT_COMPONENT_TREE */, ...params);
  }
  notifyComponentUpdate() {
    apiHooks.callHook("component:updated" /* COMPONENT_UPDATED */);
  }
  now() {
    return now();
  }
  // #endregion compatible with old devtools
  //  #region highlighter
  toggleComponentInspector(...params) {
    return toggleComponentHighLighter(...params);
  }
  inspectComponentInspector() {
    return inspectComponentHighLighter();
  }
  cancelInspectComponentInspector() {
    return cancelInspectComponentHighLighter();
  }
  scrollToComponent(...params) {
    return scrollToComponent(...params);
  }
  getComponentRenderCode(id) {
    const instance = getComponentInstance(devtoolsContext.appRecord, id);
    if (instance)
      return !((instance == null ? void 0 : instance.type) instanceof Function) ? instance.render.toString() : instance.type.toString();
  }
  getComponentBoundingRect(...params) {
    var _a10;
    const { inspectorId, instanceId = "" } = params[0];
    const _payload = {
      app: (_a10 = devtoolsContext.appRecord) == null ? void 0 : _a10.app,
      inspectorId,
      instanceId,
      rect: {
        top: 0,
        left: 0,
        width: 0,
        height: 0
      }
    };
    apiHooks.callHookWith((callbacks) => {
      callbacks.map((cb) => cb(_payload));
    }, "component-bounding-rect:get" /* GET_COMPONENT_BOUNDING_RECT */);
    return stringify(_payload.rect);
  }
  // #endregion highlighter
  toggleApp(id) {
    return toggleActiveAppRecord(id);
  }
  addCustomTab(tab) {
    addCustomTab(tab);
  }
  addCustomCommand(action) {
    addCustomCommand(action);
  }
  removeCustomCommand(actionId) {
    removeCustomCommand(actionId);
  }
  openInEditor(payload) {
    openInEditor(payload);
  }
  getVueInspector() {
    return getComponentInspector();
  }
};

// src/state/state.ts
var STATE_KEY = "__VUE_DEVTOOLS_GLOBAL_STATE__";
function initStateFactory() {
  return {
    connected: false,
    clientConnected: false,
    appRecords: [],
    activeAppRecord: null,
    selectedComponentId: null,
    pluginBuffer: [],
    tabs: [],
    commands: [],
    vitePluginDetected: false,
    activeAppRecordId: null,
    highPerfModeEnabled: false
  };
}
var _a4, _b4;
(_b4 = (_a4 = global2)[STATE_KEY]) != null ? _b4 : _a4[STATE_KEY] = initStateFactory();
var callStateUpdatedHook = debounce3((state, oldState) => {
  apiHooks.callHook("devtools:state-updated" /* DEVTOOLS_STATE_UPDATED */, state, oldState);
}, 80);
var callConnectedUpdatedHook = debounce3((state, oldState) => {
  apiHooks.callHook("devtools:connected-updated" /* DEVTOOLS_CONNECTED_UPDATED */, state, oldState);
}, 80);
var devtoolsState = new Proxy(global2[STATE_KEY], {
  get(target10, property) {
    return global2[STATE_KEY][property];
  },
  deleteProperty(target10, property) {
    delete target10[property];
    return true;
  },
  set(target10, property, value) {
    const oldState = { ...global2[STATE_KEY] };
    target10[property] = value;
    global2[STATE_KEY][property] = value;
    callStateUpdatedHook(global2[STATE_KEY], oldState);
    if (["connected", "clientConnected"].includes(property.toString()) && oldState[property] !== value)
      callConnectedUpdatedHook(global2[STATE_KEY], oldState);
    return true;
  }
});
Object.defineProperty(devtoolsState.tabs, "push", {
  configurable: true,
  value(...items) {
    const result = Array.prototype.push.apply(this, items);
    devtoolsState.tabs = this;
    apiHooks.callHook("custom-tabs:updated" /* CUSTOM_TABS_UPDATED */, this);
    return result;
  }
});
["push", "splice"].forEach((method) => {
  Object.defineProperty(devtoolsState.commands, method, {
    configurable: true,
    value(...args) {
      const result = Array.prototype[method].apply(this, args);
      devtoolsState.commands = this;
      apiHooks.callHook("custom-commands:updated" /* CUSTOM_COMMANDS_UPDATED */, this);
      return result;
    }
  });
});

// src/state/app-record.ts
init_esm_shims();
import { target as target6 } from "@vue/devtools-shared";

// src/state/router.ts
init_esm_shims();
import { target as global3 } from "@vue/devtools-shared";
var ROUTER_KEY = "__VUE_DEVTOOLS_ROUTER__";
var ROUTER_INFO_KEY = "__VUE_DEVTOOLS_ROUTER_INFO__";
var _a5, _b5;
(_b5 = (_a5 = global3)[ROUTER_INFO_KEY]) != null ? _b5 : _a5[ROUTER_INFO_KEY] = {
  currentRoute: null,
  routes: []
};
var _a6, _b6;
(_b6 = (_a6 = global3)[ROUTER_KEY]) != null ? _b6 : _a6[ROUTER_KEY] = null;
var devtoolsRouterInfo = new Proxy(global3[ROUTER_INFO_KEY], {
  get(target10, property) {
    return global3[ROUTER_INFO_KEY][property];
  }
});

// src/state/context.ts
init_esm_shims();
import { target as global4 } from "@vue/devtools-shared";
var CONTEXT_KEY = "__VUE_DEVTOOLS_CONTEXT__";
function initContextFactory() {
  return {
    appRecord: null,
    api: null,
    inspector: [],
    timelineLayer: [],
    routerInfo: {},
    router: null,
    activeInspectorTreeId: "",
    componentPluginHookBuffer: []
  };
}
var _a7, _b7;
(_b7 = (_a7 = global4)[CONTEXT_KEY]) != null ? _b7 : _a7[CONTEXT_KEY] = initContextFactory();
function resetDevToolsContext() {
  global4[CONTEXT_KEY] = initContextFactory();
}
var devtoolsContext = new Proxy(global4[CONTEXT_KEY], {
  get(target10, property) {
    if (property === "router")
      return global4[ROUTER_KEY];
    else if (property === "clear")
      return resetDevToolsContext;
    return global4[CONTEXT_KEY][property];
  },
  set(target10, property, value) {
    global4[CONTEXT_KEY][property] = value;
    return true;
  }
});

// src/state/app-record.ts
var devtoolsAppRecords = new Proxy(devtoolsState.appRecords, {
  get(_, property) {
    if (property === "value")
      return devtoolsState.appRecords;
    else if (property === "active")
      return devtoolsState.activeAppRecord;
    else if (property === "activeId")
      return devtoolsState.activeAppRecordId;
  },
  set(target10, property, value) {
    var _a10;
    const oldState = { ...devtoolsState };
    if (property === "value") {
      devtoolsState.appRecords = value;
    } else if (property === "active") {
      const _value = value;
      devtoolsState.activeAppRecord = _value;
      devtoolsContext.appRecord = _value;
      devtoolsContext.api = _value.api;
      devtoolsContext.inspector = (_a10 = _value.inspector) != null ? _a10 : [];
      normalizeRouterInfo(value, devtoolsState);
      devtoolsContext.routerInfo = devtoolsRouterInfo;
    } else if (property === "activeId") {
      devtoolsState.activeAppRecordId = value;
    }
    callStateUpdatedHook(devtoolsState, oldState);
    if (["connected", "clientConnected"].includes(property.toString()) && oldState[property] !== value)
      callConnectedUpdatedHook(devtoolsState, oldState);
    return true;
  }
});
var _a8, _b8;
var appRecordInfo = (_b8 = (_a8 = target6).__VUE_DEVTOOLS_NEXT_APP_RECORD_INFO__) != null ? _b8 : _a8.__VUE_DEVTOOLS_NEXT_APP_RECORD_INFO__ = {
  id: 0,
  appIds: /* @__PURE__ */ new Set()
};

// src/state/env.ts
init_esm_shims();
import { target as target7 } from "@vue/devtools-shared";
var _a9, _b9;
(_b9 = (_a9 = target7).__VUE_DEVTOOLS_ENV__) != null ? _b9 : _a9.__VUE_DEVTOOLS_ENV__ = {
  vitePluginDetected: false
};
function getDevToolsEnv() {
  return target7.__VUE_DEVTOOLS_ENV__;
}
function setDevToolsEnv(env) {
  target7.__VUE_DEVTOOLS_ENV__ = {
    ...target7.__VUE_DEVTOOLS_ENV__,
    ...env
  };
}

// src/compat/index.ts
init_esm_shims();
import { target as target8 } from "@vue/devtools-shared";
function onLegacyDevToolsPluginApiAvailable(cb) {
  if (target8.__VUE_DEVTOOLS_PLUGIN_API_AVAILABLE__) {
    cb();
    return;
  }
  Object.defineProperty(target8, "__VUE_DEVTOOLS_PLUGIN_API_AVAILABLE__", {
    set(value) {
      if (value)
        cb();
    },
    configurable: true
  });
}

// src/core/index.ts
function initDevTools() {
  var _a10;
  devtoolsState.vitePluginDetected = getDevToolsEnv().vitePluginDetected;
  const isDevToolsNext = ((_a10 = target9.__VUE_DEVTOOLS_GLOBAL_HOOK__) == null ? void 0 : _a10.id) === "vue-devtools-next";
  if (target9.__VUE_DEVTOOLS_GLOBAL_HOOK__ && isDevToolsNext)
    return;
  if (!target9.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
    target9.__VUE_DEVTOOLS_GLOBAL_HOOK__ = createDevToolsHook();
  } else {
    if (!isNuxtApp) {
      Object.assign(__VUE_DEVTOOLS_GLOBAL_HOOK__, createDevToolsHook());
    }
  }
  hook.on.setupDevtoolsPlugin((pluginDescriptor, setupFn) => {
    collectDevToolsPlugin(pluginDescriptor, setupFn);
    const { app, api } = devtoolsAppRecords.active || {};
    if (!app || !api)
      return;
    setupExternalPlugin([pluginDescriptor, setupFn], app, api);
  });
  onLegacyDevToolsPluginApiAvailable(() => {
    const normalizedPluginBuffer = devtoolsState.pluginBuffer.filter(([item]) => item.id !== "components");
    normalizedPluginBuffer.forEach(([pluginDescriptor, setupFn]) => {
      target9.__VUE_DEVTOOLS_GLOBAL_HOOK__.emit("devtools-plugin:setup" /* SETUP_DEVTOOLS_PLUGIN */, pluginDescriptor, setupFn, { target: "legacy" });
    });
  });
  hook.on.vueAppInit(async (app, version) => {
    const record = createAppRecord(app);
    const api = new DevToolsPluginApi();
    devtoolsAppRecords.value = [
      ...devtoolsAppRecords.value,
      {
        ...record,
        app,
        version,
        api
      }
    ];
    if (devtoolsAppRecords.value.length === 1) {
      await setActiveAppRecord(devtoolsAppRecords.value[0]);
      devtoolsState.connected = true;
      devtoolsHooks.callHook("app:connected" /* APP_CONNECTED */);
    }
  });
  hook.on.vueAppUnmount(async (app) => {
    const activeRecords = devtoolsAppRecords.value.filter((appRecord) => appRecord.app !== app);
    devtoolsAppRecords.value = activeRecords;
    if (devtoolsAppRecords.active.app === app)
      await setActiveAppRecord(activeRecords[0]);
  });
  subscribeDevToolsHook();
}
function onDevToolsConnected(fn) {
  return new Promise((resolve) => {
    if (devtoolsState.connected) {
      fn();
      resolve();
      return;
    }
    apiHooks.hook("devtools:connected-updated" /* DEVTOOLS_CONNECTED_UPDATED */, (state) => {
      if (state.connected) {
        fn();
        resolve();
      }
    });
  });
}
function onDevToolsClientConnected(fn) {
  return new Promise((resolve) => {
    if (devtoolsState.connected && devtoolsState.clientConnected) {
      fn();
      resolve();
      return;
    }
    apiHooks.hook("devtools:connected-updated" /* DEVTOOLS_CONNECTED_UPDATED */, (state) => {
      if (state.connected && state.clientConnected) {
        fn();
        resolve();
      }
    });
  });
}

// src/core/high-perf-mode/index.ts
init_esm_shims();
function toggleHighPerfMode(state) {
  devtoolsState.highPerfModeEnabled = state != null ? state : !devtoolsState.highPerfModeEnabled;
}

// src/index.ts
var devtools = {
  state: devtoolsState,
  context: devtoolsContext,
  hook,
  init: initDevTools,
  get api() {
    return devtoolsContext.api;
  }
};
export {
  INFINITY,
  NAN,
  NEGATIVE_INFINITY,
  UNDEFINED,
  addCustomCommand,
  addCustomTab,
  devtools,
  formatInspectorStateValue,
  getInspectorStateValueType,
  getRaw,
  isPlainObject,
  onDevToolsClientConnected,
  onDevToolsConnected,
  parse,
  removeCustomCommand,
  setDevToolsEnv,
  setOpenInEditorBaseUrl,
  setupDevToolsPlugin,
  stringify,
  toEdit,
  toSubmit,
  toggleComponentInspectorEnabled,
  toggleHighPerfMode
};
